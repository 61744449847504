import qs from "qs";
import { generatePath } from "react-router-dom";
import {
  CREATE_ORDER,
  DELETE_ORDER,
  GET_ALL_ORDERS,
  GET_ORDER,
  UPDATE_ORDER,
  GET_ORDER_ITEMS,
  GET_ORDERS_STATS,
  GET_ORDER_COUNT,
  ORDER_CREATE_TRANSACTION,
  GET_TRANSACTION_STATS,
  GET_TRANSACTIONS,
  REQUEST_FINAL_BILL,
  CLOSE_ORDER,
  CLOSE_ALL_ORDERS,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class OrderApi extends BaseApi {
  /**
   * Get All Orders
   */
  public getAllOrders(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_ORDERS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Order
   */
  public getOrder(id, params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${generatePath(GET_ORDER, { id })}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create Order
   */
  public createOrder(params): Promise<any> {
    return this.httpClient
      .post(CREATE_ORDER, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Update Order
   */
  public updateOrder(id, params): Promise<any> {
    return this.httpClient
      .put(generatePath(UPDATE_ORDER, { id }), params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Delete Order
   */
  public deleteOrder(id): Promise<any> {
    return this.httpClient
      .delete(generatePath(DELETE_ORDER, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Get Order Items
   */
  public getOrderItems(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_ORDER_ITEMS, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Orders Stats
   */
  public getOrdersStats(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ORDERS_STATS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Orders Count
   */
  public getOrdersCount(): Promise<any> {
    return this.httpClient
      .get(GET_ORDER_COUNT)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create transaction
   */
  public createTransaction(id, data): Promise<any> {
    return this.httpClient
      .post(generatePath(ORDER_CREATE_TRANSACTION, { id }), data)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Transaction Stats
   */
  public getTransactionStats(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_TRANSACTION_STATS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Transactions
   */
  public getTransactions(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_TRANSACTIONS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Request Final Bill
   */
  public requestFinalBill(id): Promise<any> {
    return this.httpClient
      .post(generatePath(REQUEST_FINAL_BILL, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Close Order
   */
  public closeOrder(id): Promise<any> {
    return this.httpClient
      .post(generatePath(CLOSE_ORDER, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Close all orders by restaurantId
   */
  public closeAllOrders(restaurantId): Promise<any> {
    return this.httpClient
      .post(generatePath(CLOSE_ALL_ORDERS, { restaurantId }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
