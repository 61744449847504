export const restaurantStatusState = (status: boolean) => {
  switch (status) {
    case true:
      return 'success';
    case false:
      return 'default';
    default:
      return 'default';
  }
};
