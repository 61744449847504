export const payoutStatusState = (status: string) => {
  switch (status) {
    case "completed":
      return "success";
    case "inactive":
      return "error";
    case "pending":
      return "warning";
    default:
      return "default";
  }
};
