import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { fetchAddRestaurantPayout } from "../store/slices/restaurants.slice";

interface UseProps {
  id: number;
  onSuccess: () => void;
  onError: (msg: string) => void;
}

export const usePayoutForm = ({ id, onSuccess, onError }: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      amount: "",
      comment: "",
    },
    validationSchema: yup.object().shape({
      amount: yup.number().min(0).required("Required field"),
      comment: yup.string().required("Required field").nullable(),
    }),
    onSubmit: async (values) => {
      await dispatch(
        fetchAddRestaurantPayout({
          id,
          values: { amount: +values.amount * 100, comment: values.comment },
          onSuccess: onSuccess,
          onError: onError,
        })
      );
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        const targetType = get(e, "target.type");
        const targetMin = get(e, "target.min");
        if (
          targetType === "number" &&
          !isNaN(+targetMin) &&
          +targetValue < +targetMin
        ) {
          setFieldValue(fieldName, "");
        } else if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
