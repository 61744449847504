import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { PageHeader } from '../../components/page-header';
import { useDialog } from '../../core/hooks/useDialog';
import { ConfirmDialog } from '../../components/dialogs';
import { LabeledList } from '../../components/labeled-list';
import { ROUTE_PROFILE_EDIT, ROUTE_SIGN_IN } from '../../core';
import { ChangePasswordDialog } from './dialogs/ChangePasswordDialog';
import { useUser } from '../../core/hooks/useUser';

export const ProfileContainer = () => {
  const [openConfirm, closeConfirmDialog] = useDialog(ConfirmDialog);
  const [openChangePassword] = useDialog(ChangePasswordDialog);
  const navigate = useNavigate();
  const { payload } = useUser();

  const handleLogout = () => {
    openConfirm({
      title: 'Logout',
      message: 'Are really want to logout?',
      onConfirm: () => {
        localStorage.removeItem('accessToken');
        closeConfirmDialog();
        navigate(ROUTE_SIGN_IN);
      }
    });
  };

  const handleChangePassword = () => {
    openChangePassword();
  };

  return (
    <>
      <PageHeader title="Profile">
        <Button
          variant="contained"
          color="secondary"
          onClick={handleChangePassword}
        >
          Change password
        </Button>
        <Button color="secondary" component={Link} to={ROUTE_PROFILE_EDIT}>
          Edit
        </Button>
        <Button variant="outlined" color="secondary" onClick={handleLogout}>
          Logout
        </Button>
      </PageHeader>

      <LabeledList
        items={[
          {
            label: 'Name',
            value: payload?.fullName
          },
          {
            label: 'Email',
            value: payload?.email
          },
          {
            label: 'Role',
            value: payload?.role
          }
        ]}
      />
    </>
  );
};
