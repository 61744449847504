import { format } from "date-fns";
import { StatusBadge } from "../../components/badges";
import { DISPLAY_DATE_FORMAT, restaurantStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const waiterRestaurantColumns = (waiterId) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, row) => (
      <StatusBadge
        label={row.isActive ? "Active" : "Inactive"}
        variant={restaurantStatusState(row.isActive)}
      />
    ),
  },
  {
    title: "Rest. Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Connection Date",
    dataIndex: "connectedDate",
    key: "connectedDate",
    render: (_, row) => {
      const waiter = row.waiters.find((item) => item.waiterId === +waiterId);
      if (waiter) {
        return format(new Date(waiter.connectedAt), DISPLAY_DATE_FORMAT);
      }
      return "-";
    },
  },
  {
    title: "Orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
  },
  {
    title: "Total Income",
    dataIndex: "totalIncome",
    key: "totalIncome",
    render: (totalIncome) => formatCurrency(totalIncome),
  },
];
