import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { fetchGetRestaurantBalance } from "../store/slices/restaurants.slice";

export const useRestaurantBalance = (id) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.restaurants.balance
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetRestaurantBalance(id));
  }, [id, dispatch]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return { payload, errors, loading };
};
