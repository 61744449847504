import { LabeledList } from "../../../components/labeled-list";
import { Typography } from "@mui/material";
import { format } from "date-fns";
import { DISPLAY_DATE_FORMAT } from "../../../core";

export const Detail = ({ payload }) => {
  const posConfigItems = payload?.omnivoreLocationId
    ? [
        {
          label: "Omnivore ID",
          value: payload?.omnivoreLocationId,
        },
      ]
    : [
        { label: "Comtrex Url", value: payload?.comtrex?.url || "-" },
        { label: "Comtrex Login", value: payload?.comtrex?.username || "-" },
        { label: "Comtrex Password", value: payload?.comtrex?.password || "-" },
      ];
  return (
    <>
      <LabeledList
        mb={3}
        items={[
          {
            label: "ID",
            value: payload?.id,
          },
          {
            label: "Pin code",
            value: payload?.pinCode,
          },
          {
            label: "Name",
            value: payload?.name,
          },
          {
            label: "Phone",
            value: payload?.phone,
          },
          {
            label: "E-mail",
            value: payload?.email,
          },
          {
            label: "Notes",
            value: payload?.notes,
          },
          {
            label: "Created",
            value: payload?.createdAt
              ? format(new Date(payload?.createdAt), DISPLAY_DATE_FORMAT)
              : "-",
          },
          {
            label: "Updated",
            value: payload?.updatedAt
              ? format(new Date(payload?.updatedAt), DISPLAY_DATE_FORMAT)
              : "-",
          },
        ]}
      />

      <Typography variant="h6" mb={3}>
        Banking information
      </Typography>
      <LabeledList
        mb={3}
        items={[
          {
            label: "Sort code",
            value: payload?.sortCode,
          },
          {
            label: "Account number",
            value: payload?.accountNumber,
          },
          {
            label: "Postal code",
            value: payload?.postCode,
          },
        ]}
      />
      <Typography variant="h6" mb={3}>
        Pos Config
      </Typography>
      <LabeledList mb={3} items={posConfigItems} />
    </>
  );
};
