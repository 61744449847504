import qs from "qs";
import { GET_ALL_SUBSCRIBERS, GET_SUBSCRIBERS_COUNT } from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class SubscriberApi extends BaseApi {
  /**
   * Get All Subscribers
   */
  public getAllSubscriber(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_SUBSCRIBERS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Subscribers Count
   */
  public getSubscriberCount(): Promise<any> {
    return this.httpClient
      .get(GET_SUBSCRIBERS_COUNT)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
