import { PageHeader } from "../../components/page-header";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { useNavigate } from "react-router-dom";
import { url, orderColumns, ROUTE_ORDERS_DETAIL } from "../../core";
import { FieldDateRange, FieldSearch } from "../../components/fields";
import { useFormik } from "formik";
import { StatsCard } from "../../components/stats-card";
import { Box } from "@mui/system";
import { useRestaurantsAutocomplete } from "../../core/hooks/useRestaurantsAutocomplete";
import { useWaitersAutocomplete } from "../../core/hooks/useWaitersAutocomplete";
import { useEffect, useMemo, useState } from "react";
import { DateRange } from "react-day-picker";
import qs from "qs";
import { formatCurrency } from "../../core/utils/currency-format.utils";
import { FieldAutocomplete } from "../../components/fields/field-autocomplete";
import useDebounce from "../../core/hooks/useDebounce";
import { useListResource } from "../../core/hooks/useListResource";
import {
  fetchGetAllOrders,
  fetchGetOrdersStats,
  fetchGetTransactionStats,
} from "../../core/store/slices/orders.slice";
import { useStatsResource } from "../../core/hooks/useStatsResource";
import { useCount } from "../../core/hooks/useCount";
import { endOfDay, startOfDay } from "date-fns";

export const OrderListContainer = () => {
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const navigate = useNavigate();
  const {
    payload: ordersStatsPayload,
    loading: ordersStatsLoading,
    handleChangeParams: handleStatsChangeParams,
  } = useStatsResource({}, "orders", "findStats", fetchGetOrdersStats);
  const { payload: restaurantsPayload, loading: restaurantsLoading } =
    useRestaurantsAutocomplete();
  const { payload: waitersPayload, loading: waitersLoading } =
    useWaitersAutocomplete();
  const { payload, pagination, loading, handlePageChange, handleChangeParams } =
    useListResource(
      {
        embed: "restaurant,waiter,client,table",
        order: "createdAt",
      },
      "orders",
      "findAll",
      fetchGetAllOrders
    );

  const {
    payload: transactionStatsPayload,
    handleChangeParams: handleTransactionStatsChangeParams,
    loading: transactionStatsLoading,
  } = useStatsResource(
    {
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
    },
    "orders",
    "findTransactionStats",
    fetchGetTransactionStats
  );

  const [range, setRange] = useState<DateRange | undefined>(undefined);

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      restaurantId: "",
      waiterId: "",
      dateFrom: undefined,
      dateTo: undefined,
    },
    onSubmit: (values) => {
      handleStatsChangeParams(values);
      handleChangeParams(values);
      handleTransactionStatsChangeParams(values);
    },
  });

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);
    headers.append("offset", new Date().getTimezoneOffset().toString());

    const queryString = qs.stringify(
      { ...values, embed: "restaurant,waiter,client" },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/orders/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "orders.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };
  const { orderCount } = useCount();

  useEffect(() => {
    if (range) {
      setFieldValue("dateFrom", range.from || undefined);
      setFieldValue("dateTo", range.to || undefined);
    }
  }, [range, setFieldValue]);

  const restaurantOptions = useMemo(
    () =>
      restaurantsPayload?.results
        ? restaurantsPayload?.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        : [],
    [restaurantsPayload]
  );

  const waiterOptions = useMemo(
    () =>
      waitersPayload?.results
        ? waitersPayload?.results.map((item) => ({
            value: item.id,
            label: item.fullName,
          }))
        : [],
    [waitersPayload]
  );

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [handleSubmit]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader
          title="Orders"
          meta={
            <Typography color="text.disabled" variant="h3" component="span">
              {orderCount}
            </Typography>
          }
        />
        <Button
          color="secondary"
          sx={{ height: 36 }}
          onClick={() => handleExport()}
          size="small"
        >
          Export
        </Button>
      </Grid>

      <Grid container columnSpacing={2} mb={3}>
        <Grid item sm={5.5}>
          <FieldDateRange width="50%" range={range} setRange={setRange} />
        </Grid>
        <Grid item sm={2.75}>
          <FieldAutocomplete
            fullWidth={true}
            label="Restaurant"
            loading={restaurantsLoading}
            disabled={restaurantsLoading}
            placeholder="Select"
            dataSource={restaurantOptions}
            value={restaurantOptions.find(
              (item) => item.value === values.restaurantId
            )}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue("restaurantId", value.value);
              } else {
                setFieldValue("restaurantId", "");
              }
              handleSubmit();
            }}
          />
        </Grid>
        <Grid item sm={2.75}>
          <FieldAutocomplete
            label="Waiter"
            placeholder="Select"
            loading={waitersLoading}
            disabled={waitersLoading}
            dataSource={waiterOptions}
            value={waiterOptions.find((item) => item.value === values.waiterId)}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue("waiterId", value.value);
              } else {
                setFieldValue("waiterId", "");
              }
              handleSubmit();
            }}
          />
        </Grid>
        <Grid item sm={1}>
          <Button
            color="secondary"
            fullWidth
            sx={{ height: 56 }}
            onClick={() => handleSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <StatsCard
            title="Orders Total"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                <>
                  {formatCurrency(ordersStatsPayload?.totalIncome)}{" "}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {ordersStatsLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    ordersStatsPayload?.ordersCount
                  )}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={6}>
          <StatsCard
            title="Av. Bill"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.dateFrom || !values.dateTo
                    ? "-"
                    : formatCurrency(ordersStatsPayload?.avgBill)}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Orders (last month)"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                <>
                  {formatCurrency(ordersStatsPayload?.incomeLastMonth)}{" "}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {ordersStatsLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    ordersStatsPayload?.ordersLastMonth
                  )}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Credited (last month)"
            value={
              transactionStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                <>
                  {formatCurrency(
                    transactionStatsPayload?.totalCreditLastMonth
                  )}{" "}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {transactionStatsPayload?.creditsCountLastMonth}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Refunded (last month)"
            value={
              transactionStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                <>
                  {formatCurrency(
                    transactionStatsPayload?.totalRefundLastMonth
                  )}{" "}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {transactionStatsPayload?.refundsCountLastMonth}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
      </Grid>
      <Box my={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>

      <TableView
        showPagination
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) =>
          navigate(url(ROUTE_ORDERS_DETAIL, { orderId: id }))
        }
        dataSource={payload?.results || []}
        columns={orderColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
