import { Button } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { DetailHeader } from "../../components/detail-header";
import { useParams } from "react-router-dom";
import { orderStatusState, ROUTE_ORDERS } from "../../core";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { OrderDetail } from "./OrderDetail";
import { useDialog } from "../../core/hooks/useDialog";
import { MakeTransactionDialog } from "./dialogs/MakeTransactionDialog";
import { useOrder } from "../../core/hooks/useOrder";
import { ConfirmDialog } from "../../components/dialogs";
import {
  fetchRequestFinalBill,
  fetchCloseOrder,
} from "../../core/store/slices/orders.slice";
import { useDispatch } from "react-redux";
import { useSnackbar } from "../../core/hooks/useSnackbar";

export const OrderDetailContainer = () => {
  const { orderId } = useParams();
  const dispatch = useDispatch();
  const [openMakeTransaction] = useDialog(MakeTransactionDialog);
  const { showMessage } = useSnackbar();

  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const handleMakeTransaction = () => {
    openMakeTransaction({ id: orderId, total: payload?.total, fetchData });
  };

  const handleCloseOrder = () => {
    openConfirm({
      title: "Close order without charge",
      message: "Are really want to close order without charge?",
      onConfirm: async () => {
        await dispatch(fetchCloseOrder(orderId));
        fetchData();
        showMessage(
          "Order has been closed successfully without charge",
          "success"
        );
        closeConfirm();
      },
    });
  };

  const handleFinalBill = () => {
    openConfirm({
      title: "Request final bill",
      message: "Are really want to request final bill?",
      onConfirm: async () => {
        await dispatch(fetchRequestFinalBill(orderId));
        fetchData();
        closeConfirm();
      },
    });
  };

  const {
    payload,
    itemsPayload,
    itemsLoading,
    paymentsPayload,
    paymentsLoading,
    itemsHandleChangePage,
    fetchData,
  } = useOrder(orderId, { embed: "restaurant,waiter,client,table" });

  return (
    <>
      <DetailHeader
        title={`Order #${orderId}`}
        header={
          <Breadcrumbs
            items={[
              { label: "Orders", url: ROUTE_ORDERS },
              { label: `#${orderId}` },
            ]}
          />
        }
        meta={
          <StatusBadge
            label={payload?.isPaid ? "Paid" : "Unpaid"}
            variant={orderStatusState(payload?.isPaid)}
            icon
          />
        }
      >
        <Button color="secondary" onClick={handleCloseOrder}>
          Close order (without charge)
        </Button>
        {payload && !payload?.billRequestedAt && !!payload?.client && payload?.total && (
          <Button onClick={handleFinalBill}>Request final bill</Button>
        )}
        <Button color="secondary" onClick={handleMakeTransaction}>
          Make a transaction
        </Button>
      </DetailHeader>

      <OrderDetail
        payload={payload}
        itemsPayload={itemsPayload}
        itemsLoading={itemsLoading}
        paymentsPayload={paymentsPayload}
        paymentsLoading={paymentsLoading}
        itemsHandleChangePage={itemsHandleChangePage}
      />
    </>
  );
};
