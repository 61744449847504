import { LabeledList } from "../../../components/labeled-list";
import { format } from "date-fns";
import { DISPLAY_DATE_FORMAT } from "../../../core";

export const Detail = ({ payload }) => {
  return (
    <LabeledList
      mb={3}
      items={[
        {
          label: "ID",
          value: payload?.id || "-",
        },
        {
          label: "Alias",
          value: payload?.alias || "-",
        },
        {
          label: "Name",
          value: payload?.name || "-",
        },
        {
          label: "Number",
          value: payload?.number || "-",
        },
        {
          label: "Seats",
          value: payload?.seats || "-",
        },
        {
          label: "Created",
          value: payload?.createdAt
            ? format(new Date(payload?.createdAt), DISPLAY_DATE_FORMAT)
            : "-",
        },
        {
          label: "Updated",
          value: payload?.updatedAt
            ? format(new Date(payload?.updatedAt), DISPLAY_DATE_FORMAT)
            : "-",
        },
      ]}
    />
  );
};
