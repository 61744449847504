import { FC } from "react";
import { Box, Grid, Typography, Link as MuiLink } from "@mui/material";
import { LabeledList } from "../../components/labeled-list";
import { Link } from "react-router-dom";
import {
  DISPLAY_DATE_FORMAT,
  orderSummaryColumns,
  orderTransactionsColumns,
  ROUTE_CLIENTS_DETAIL,
  ROUTE_RESTAURANTS_DETAIL,
  ROUTE_WAITERS_DETAIL,
  url,
} from "../../core";
import { TableView } from "../../components/table-view";
import { format } from "date-fns";
import { formatCurrency } from "../../core/utils/currency-format.utils";

interface Props {
  history?: any[];
  payload?: any;
  itemsPagination?: any;
  itemsPayload?: any;
  itemsLoading?: boolean;
  paymentsPayload?: any;
  paymentsLoading?: boolean;
  itemsHandleChangePage?: any;
}

export const OrderDetail: FC<Props> = ({
  history,
  payload,
  itemsPagination,
  itemsPayload,
  itemsLoading,
  paymentsPayload,
  paymentsLoading,
  itemsHandleChangePage,
}) => {
  return (
    <>
      <Grid container columnSpacing={5}>
        <Grid item width={380}>
          <Box
            sx={{
              backgroundColor: "#F4F6F8",
              p: 3,
              height: "100%",
              borderRadius: 1.5,
            }}
          >
            <Typography variant="h6" mb={2}>
              Order details
            </Typography>
            <LabeledList
              mb={3}
              spacing={1.5}
              items={[
                {
                  label: "Created",
                  value: payload?.createdAt
                    ? format(new Date(payload?.createdAt), DISPLAY_DATE_FORMAT)
                    : "-",
                },
                {
                  label: "Bill requested",
                  value: payload?.billRequestedAt
                    ? format(
                        new Date(payload?.billRequestedAt),
                        DISPLAY_DATE_FORMAT
                      )
                    : "-",
                },
                {
                  label: "Closed",
                  value: payload?.closedAt
                    ? format(new Date(payload?.closedAt), DISPLAY_DATE_FORMAT)
                    : "-",
                },
                {
                  label: "Table",
                  value: payload?.table?.alias || payload?.table?.number,
                },
                {
                  label: "Items",
                  value: itemsPayload?.results.length,
                },
                {
                  label: "Subtotal",
                  value: formatCurrency(payload?.subTotal) || "-",
                  shouldHideIf: !payload?.subTotal,
                },
                {
                  label: "Tax",
                  value: formatCurrency(payload?.totalTax) || "-",
                  shouldHideIf: !payload?.totalTax,
                },
                {
                  label: "Refunds",
                  value: formatCurrency(payload?.totalRefunds) || "-",
                  shouldHideIf: !payload?.totalRefunds,
                },
                {
                  label: "Refunded At",
                  value: payload?.refundedAt
                    ? format(new Date(payload?.refundedAt), DISPLAY_DATE_FORMAT)
                    : "-",
                  shouldHideIf: !payload?.totalRefunds,
                },
                {
                  label: "Additional Charges",
                  value: formatCurrency(payload?.totalAdditionalCharges) || "-",
                  shouldHideIf: !payload?.totalAdditionalCharges,
                },
                {
                  label: "Service Charges",
                  value: formatCurrency(payload?.totalServiceCharges) || "-",
                  shouldHideIf: !payload?.totalServiceCharges,
                },
                {
                  label: "Credit",
                  value: formatCurrency(-1 * payload?.totalCredit) || "-",
                  shouldHideIf: !payload?.totalCredit,
                  divider: true,
                },
                {
                  label: "Total",
                  value: formatCurrency(payload?.totalFinal) || "-",
                },
              ]}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box py={3}>
            {payload?.client && (
              <>
                <Typography variant="h6" mb={2}>
                  Client
                </Typography>
                <LabeledList
                  mb={3}
                  spacing={1.5}
                  items={[
                    {
                      label: "Name",
                      value: (
                        <MuiLink
                          component={Link}
                          to={url(ROUTE_CLIENTS_DETAIL, {
                            id: payload.client.id,
                          })}
                          color="#235CE5"
                        >
                          {payload.client.fullName}
                        </MuiLink>
                      ),
                    },
                    {
                      label: "Phone",
                      value: payload.client.phone,
                    },
                  ]}
                />
              </>
            )}
            {payload?.restaurant && (
              <>
                <Typography variant="h6" mb={2}>
                  Restaurant
                </Typography>
                <LabeledList
                  mb={3}
                  spacing={1.5}
                  items={[
                    {
                      label: "Name",
                      value: (
                        <MuiLink
                          component={Link}
                          to={url(ROUTE_RESTAURANTS_DETAIL, {
                            id: payload.restaurant.id,
                          })}
                          color="#235CE5"
                        >
                          {payload.restaurant.name}
                        </MuiLink>
                      ),
                    },
                    {
                      label: "Phone",
                      value: "+44 7911 123456",
                    },
                    {
                      label: "Address",
                      value: "2118 Thornridge Cir. Syracuse, Connecticut 35624",
                    },
                  ]}
                />
              </>
            )}
            {payload?.waiter && (
              <>
                <Typography variant="h6" mb={2}>
                  Waiter
                </Typography>
                <LabeledList
                  spacing={1.5}
                  items={[
                    {
                      label: "ID",
                      value: payload.waiter.id,
                    },
                    {
                      label: "Name",
                      value: (
                        <MuiLink
                          component={Link}
                          to={url(ROUTE_WAITERS_DETAIL, {
                            id: payload.waiter.id,
                          })}
                          color="#235CE5"
                        >
                          {payload.waiter.fullName}
                        </MuiLink>
                      ),
                    },
                    {
                      label: "Phone",
                      value: payload.waiter.phone,
                    },
                  ]}
                />
              </>
            )}
          </Box>
        </Grid>
      </Grid>

      <Box py={3}>
        <Typography variant="h6" mb={2}>
          Order summary
        </Typography>
        <TableView
          showPagination
          pagination={itemsPagination}
          onChangePage={itemsHandleChangePage}
          loading={itemsLoading}
          dataSource={itemsPayload?.results || []}
          columns={orderSummaryColumns}
          showEmptyState
        />
      </Box>

      {paymentsPayload?.results && !!paymentsPayload?.results.length && (
        <Box py={3}>
          <Typography variant="h6" mb={2}>
            Transactions history
          </Typography>
          <TableView
            showPagination
            pagination={itemsPagination}
            onChangePage={itemsHandleChangePage}
            loading={paymentsLoading}
            dataSource={paymentsPayload?.results || []}
            columns={orderTransactionsColumns}
            showEmptyState
          />
        </Box>
      )}
    </>
  );
};
