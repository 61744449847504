import { useState } from "react";
import { Button } from "@mui/material";
import { Tabs } from "../../components/tabs";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { Link, useParams } from "react-router-dom";
import { ROUTE_TABLES, ROUTE_TABLES_EDIT, url } from "../../core";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { useTable } from "../../core/hooks/useTables";

export const TablesDetailContainer = () => {
  const { id } = useParams();
  const { payload } = useTable(id);
  const [selectedTab, setSelectedTab] = useState<string>("details");

  return (
    <>
      <DetailHeader
        title={payload?.alias}
        divider={false}
        header={
          <Breadcrumbs
            items={[
              { label: "Tables", url: ROUTE_TABLES },
              { label: payload?.alias },
            ]}
          />
        }
      >
        {selectedTab === "details" && (
          <Button component={Link} to={url(ROUTE_TABLES_EDIT, { id })}>
            Edit
          </Button>
        )}
      </DetailHeader>

      <Tabs
        hashed
        onChange={(value) => setSelectedTab(value)}
        items={[
          {
            label: "Details",
            key: "details",
            content: <Detail payload={payload} />,
          },
        ]}
      />
    </>
  );
};
