import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "stats-card",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      padding: 24,
      borderRadius: 16,
    },
    white: {
      backgroundColor: "#fff",
      boxShadow: "0px 8px 16px rgba(145, 158, 171, 0.12)",
    },
    grey: {
      backgroundColor: "#F4F6F8",
    },
  }),
  options
);

export default useStyles;
