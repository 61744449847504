import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { fetchGetAllWaiters } from "../store/slices/waiters.slice";

export const useWaitersAutocomplete = () => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.waiters.findAll
  );

  const fetchData = useCallback(() => {
    dispatch(
      fetchGetAllWaiters({
        limit: 100,
        page: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (payload?.results?.length) {
      return;
    }
    fetchData();
  }, [fetchData, payload?.results?.length]);

  return { payload, errors, loading };
};
