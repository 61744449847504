import { StatusBadge } from "../../components/badges";
import { waiterStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const restaurantWaiterColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "waiter.status",
    key: "waiter.status",
    render: (_, row) => (
      <StatusBadge
        label={row.isActive ? "Active" : "Inactive"}
        variant={waiterStatusState(row.isActive)}
      />
    ),
  },
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Orders Total",
    dataIndex: "ordersCount",
    key: "ordersCount",
  },
  {
    title: "Total",
    dataIndex: "totalIncome",
    key: "totalIncome",
    render: (totalIncome) => formatCurrency(totalIncome),
  },
];
