import { ComponentType, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Stack, TextField } from "@mui/material";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { usePayoutForm } from "../../../core/hooks/usePayoutForm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGetRestaurantBalance,
  fetchRestaurantPayouts,
} from "../../../core/store/slices/restaurants.slice";
import { AppState } from "../../../core/store";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

interface Props extends DialogProps {
  id: number;
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  body?: ReactNode;
}

export const NewPayoutDialog: ComponentType<Props> = ({ id, popDialog }) => {
  const dispatch = useDispatch();
  const { showMessage } = useSnackbar();
  const { getFieldProps, handleSubmit } = usePayoutForm({
    id,
    onSuccess: () => {
      dispatch(fetchRestaurantPayouts({ restaurantId: id }));
      dispatch(fetchGetRestaurantBalance(id));
      popDialog();
    },
    onError: (error) => {
      showMessage(error, "error");
    },
  });

  const balance = useSelector(
    (state: AppState) => state.restaurants.balance?.payload?.balance
  );

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { minWidth: 440 } }}
    >
      <DialogTitle>Add new payout</DialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={1}>
          <TextField
            label="Amount"
            type="number"
            inputProps={{ min: 0 }}
            {...getFieldProps("amount")}
            helperText={`Current balance: ${formatCurrency(balance)}`}
          />
          <TextField
            label="Comment"
            multiline
            rows={4}
            {...getFieldProps("comment")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
