import { createSlice } from "@reduxjs/toolkit";
import { OrderApi } from "../../http/api/order.api";
import { AsyncState, createFetchAsync } from "../utils";

const orderApi = new OrderApi();

export const fetchGetAllOrdersAsync = createFetchAsync(
  "order",
  "findAll",
  async (params) => {
    try {
      const response = await orderApi.getAllOrders(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllOrders = fetchGetAllOrdersAsync.asyncThunk;

export const fetchGetOrderAsync = createFetchAsync(
  "order",
  "find",
  async ({ id, params }) => {
    try {
      const response = await orderApi.getOrder(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetOrder = fetchGetOrderAsync.asyncThunk;

export const fetchCreateOrderAsync = createFetchAsync(
  "order",
  "create",
  async (params) => {
    try {
      const response = await orderApi.createOrder(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCreateOrder = fetchCreateOrderAsync.asyncThunk;

export const fetchUpdateOrderAsync = createFetchAsync(
  "order",
  "create",
  async ({ id, params }) => {
    try {
      const response = await orderApi.updateOrder(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchUpdateOrder = fetchUpdateOrderAsync.asyncThunk;

export const fetchDeleteOrderAsync = createFetchAsync(
  "order",
  "delete",
  async (id) => {
    try {
      const response = await orderApi.deleteOrder(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDeleteOrder = fetchDeleteOrderAsync.asyncThunk;

export const fetchGetOrderItemsAsync = createFetchAsync(
  "order",
  "findAllItems",
  async (id) => {
    try {
      const response = await orderApi.getOrderItems(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetOrderItems = fetchGetOrderItemsAsync.asyncThunk;

export const fetchGetOrdersStatsAsync = createFetchAsync(
  "order",
  "findStats",
  async (params) => {
    try {
      const response = await orderApi.getOrdersStats(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetOrdersStats = fetchGetOrdersStatsAsync.asyncThunk;

export const fetchGetOrdersCountAsync = createFetchAsync(
  "order",
  "count",
  async () => {
    try {
      const response = await orderApi.getOrdersCount();
      return response.count;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetOrdersCount = fetchGetOrdersCountAsync.asyncThunk;

export const fetchCreateTransactionAsync = createFetchAsync(
  "order",
  "createTransaction",
  async ({ id, values, onSuccess, onError }) => {
    try {
      const response = await orderApi.createTransaction(id, values);
      onSuccess();
      return response.data;
    } catch (error) {
      onError(error.message);
      throw new Error(error);
    }
  }
);

export const fetchCreateTransaction = fetchCreateTransactionAsync.asyncThunk;

export const fetchGetTransactionStatsAsync = createFetchAsync(
  "order",
  "findTransactionStats",
  async (params) => {
    try {
      const response = await orderApi.getTransactionStats(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetTransactionStats =
  fetchGetTransactionStatsAsync.asyncThunk;

export const fetchGetTransactionsAsync = createFetchAsync(
  "order",
  "findTransactions",
  async (params) => {
    try {
      const response = await orderApi.getTransactions(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetTransactions = fetchGetTransactionsAsync.asyncThunk;

export const fetchRequestFinalBillAsync = createFetchAsync(
  "order",
  "requestFinalBill",
  async (params) => {
    try {
      const response = await orderApi.requestFinalBill(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchRequestFinalBill = fetchRequestFinalBillAsync.asyncThunk;

export const fetchCloseOrderAsync = createFetchAsync(
  "order",
  "closeOrder",
  async (params) => {
    try {
      const response = await orderApi.closeOrder(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCloseOrder = fetchCloseOrderAsync.asyncThunk;

export const fetchCloseAllOrdersAsync = createFetchAsync(
  "order",
  "closeAllOrders",
  async (params) => {
    try {
      await orderApi.closeAllOrders(params);
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchCloseAllOrders = fetchCloseAllOrdersAsync.asyncThunk;

type OrderState = {
  findAll: AsyncState<any>;
  find: AsyncState<any>;
  create: AsyncState<any>;
  update: AsyncState<any>;
  delete: AsyncState<any>;
  findAllItems: AsyncState<any>;
  findStats: AsyncState<any>;
  count: AsyncState<any>;
  createTransaction: AsyncState<any>;
  findTransactionStats: AsyncState<any>;
  findTransactions: AsyncState<any>;
  requestFinalBill: AsyncState<any>;
  closeOrder: AsyncState<any>;
  closeAllOrders: AsyncState<any>;
};

const initialState: OrderState = {
  findAll: fetchGetAllOrdersAsync.initialState,
  find: fetchGetOrderAsync.initialState,
  create: fetchCreateOrderAsync.initialState,
  update: fetchUpdateOrderAsync.initialState,
  delete: fetchDeleteOrderAsync.initialState,
  findAllItems: fetchGetOrderItemsAsync.initialState,
  findStats: fetchGetOrdersStatsAsync.initialState,
  count: fetchGetOrdersCountAsync.initialState,
  createTransaction: fetchCreateTransactionAsync.initialState,
  findTransactionStats: fetchGetTransactionStatsAsync.initialState,
  findTransactions: fetchGetTransactionsAsync.initialState,
  requestFinalBill: fetchRequestFinalBillAsync.initialState,
  closeOrder: fetchCloseOrderAsync.initialState,
  closeAllOrders: fetchCloseAllOrdersAsync.initialState,
};

export const orderSlice = createSlice({
  name: "ordersSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllOrdersAsync.extraReducers,
    ...fetchGetOrderAsync.extraReducers,
    ...fetchCreateOrderAsync.extraReducers,
    ...fetchUpdateOrderAsync.extraReducers,
    ...fetchDeleteOrderAsync.extraReducers,
    ...fetchGetOrderItemsAsync.extraReducers,
    ...fetchGetOrdersStatsAsync.extraReducers,
    ...fetchGetOrdersCountAsync.extraReducers,
    ...fetchCreateTransactionAsync.extraReducers,
    ...fetchGetTransactionStatsAsync.extraReducers,
    ...fetchGetTransactionsAsync.extraReducers,
    ...fetchRequestFinalBillAsync.extraReducers,
    ...fetchCloseOrderAsync.extraReducers,
    ...fetchCloseAllOrdersAsync.extraReducers,
  },
});

export default orderSlice.reducer;
