export const clientStatusState = (status: boolean) => {
  switch (status) {
    case true:
      return "success";
    case false:
      return "error";
    default:
      return "default";
  }
};
