import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { fetchChangePassword } from "../store/slices/auth.slice";

interface UseProps {
  onSuccess: () => void;
  onError: () => void;
}

const passwordMessage =
  "Password must contain at least 8 characters, including at least one number, one lowercase and one uppercase";

export const useChangePassword = ({ onSuccess, onError }: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: yup.object().shape({
      password: yup.string().required("Password is required"),
      newPassword: yup
        .string()
        .min(8, passwordMessage)
        .test("isValidPass", passwordMessage, (value, context) => {
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          return hasUpperCase && hasLowerCase && hasNumber;
        })
        .required("Password is required")
        .notOneOf(
          [yup.ref("password"), null],
          "New password should not be the same as previous"
        ),

      passwordConfirmation: yup
        .string()
        .required("Confirm password is required")
        .oneOf([yup.ref("newPassword"), null], "Passwords don’t match"),
    }),
    onSubmit: async (values) => {
      await dispatch(
        fetchChangePassword({
          values,
          onSuccess: () => {
            onSuccess();
          },
          onError: () => {
            onError();
          },
        })
      );
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
