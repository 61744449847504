import TextTruncate from "react-text-truncate";
import { StatusBadge } from "../../components/badges";
import { restaurantStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const restaurantColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, row) => (
      <StatusBadge
        label={row.isActive ? "Active" : "Inactive"}
        variant={restaurantStatusState(row.isActive)}
      />
    ),
  },
  {
    title: "Rest. Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    cellProps: {
      sx: {
        width: 213,
      },
    },
    render: (address) => (
      <TextTruncate line={1} truncateText="…" text={address} />
    ),
  },
  {
    title: "Waiters",
    dataIndex: "waiters",
    key: "waiters",
    render: (w) => w?.length,
  },
  {
    title: "Total Income",
    dataIndex: "totalIncome",
    key: "totalIncome",
    render: (income) => formatCurrency(income),
  },
];
