import qs from "qs";
import { generatePath } from "react-router-dom";
import {
  ACTIVATE_WAITER,
  CREATE_WAITER,
  DEACTIVATE_WAITER,
  GET_ALL_WAITERS,
  GET_WAITER,
  GET_WAITER_COUNT,
  UPDATE_WAITER,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class WaiterApi extends BaseApi {
  /**
   * Get All Waiters
   */
  public getAllWaiters(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_WAITERS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Waiter
   */
  public getWaiter(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_WAITER, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Waiter Count
   */
  public getWaiterCount(): Promise<any> {
    return this.httpClient
      .get(GET_WAITER_COUNT)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create Waiter
   */
  public createWaiter(params): Promise<any> {
    return this.httpClient
      .post(CREATE_WAITER, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Update Waiter
   */
  public updateWaiter(id, params): Promise<any> {
    return this.httpClient
      .put(generatePath(UPDATE_WAITER, { id }), params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Deactivate Waiter
   */
  public deactivateWaiter(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(DEACTIVATE_WAITER, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Activate Waiter
   */
  public activateWaiter(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(ACTIVATE_WAITER, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
