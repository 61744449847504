import { AxiosRequestConfig } from 'axios';
import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;

export const axiosConfig: AxiosRequestConfig = {
  timeout: 30000,
  baseURL,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
};

const responseSuccessHandler = (response) => {
  return response;
};

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken');
    window.location.href = '/';
  }
  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);
