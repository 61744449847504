export const handleError = (response) => {
  if (response?.data?.message && typeof response?.data?.message === "object") {
    return response.data.message[Object.keys(response.data.message)[0]];
  }
  return "Something went wrong";
};

const getNames = (fullName?: string) => {
  if (!fullName) {
    return null;
  }
  let arr = fullName.split(" ");
  if (arr.length === 1) {
    return { firstName: arr[0], lastName: " " };
  } else if (arr.length === 2) {
    return { firstName: arr[0], lastName: arr[1] };
  } else if (arr.length > 2) {
    return {
      firstName: myJoin(arr, " ", 0, 1),
      lastName: myJoin(arr, " ", 2, arr.length - 1),
    };
  }
  return null;
};

const myJoin = (
  arr: Array<string>,
  separator: string,
  start?: number,
  end?: number
) => {
  if (!start) start = 0;
  if (!end) end = arr.length - 1;
  end++;
  return arr.slice(start, end).join(separator);
};

export const getInitials = (fullName?: string) => {
  if (!fullName) {
    return "NA";
  }

  const names = getNames(fullName);
  return `${names?.firstName.charAt(0)}${names?.lastName.charAt(0)}`;
};
