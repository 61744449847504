import { Components, createTheme } from "@mui/material";
import { PaletteOptions } from "@mui/material/styles/createPalette";
import { typography } from "./typography";

export const themeComponents = (palette: PaletteOptions): Components => {
  const theme = createTheme();

  return {
    MuiTextField: {
      defaultProps: {
        fullWidth: true,
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: palette.text.primary,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(145, 158, 171, 0.32)",
        },
        input: {
          color: palette.text.primary,
          "&::placeholder": {
            opacity: 1,
            color: "#212B36",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      styleOverrides: {
        containedPrimary: {
          backgroundColor: theme.palette.common.black,
          "&:hover": {
            backgroundColor: theme.palette.common.black,
            opacity: 0.9,
          },
        },
        sizeLarge: {
          padding: theme.spacing(1.375, 2.75),
          fontSize: 15,
          lineHeight: "26px",
        },
        sizeSmall: {
          fontSize: 13,
          lineHeight: "22px",
        },
        outlinedSecondary: {
          border: "1px solid rgba(145, 158, 171, 0.32)",
          color: "#212B36",
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#F4F6F8",
          "& .MuiTableCell-root": {
            color: "#637381",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "none",
            padding: theme.spacing(1.125, 2),
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableCell-root": {
            ...typography.body2,
            lineHeight: "22px",
            padding: theme.spacing(2.625, 2),
            borderBottom: "1px solid #F4F6F8",
          },
          "& .MuiTableRow-root:hover": {
            backgroundColor: "#F9FBFE",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "common.dark",
          borderColor: "#ECF0F7",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          " &:hover": {
            cursor: "pointer",
          },
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: 33,
        },
        indicator: {
          height: 2,
          backgroundColor: "#5B8F90",
          borderRadius: 2,
          padding: 0,
          zIndex: 10,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: 14,
          lineHeight: "22px",
          fontFamily: "Inter, sans-serif",
          color: "#637381",
          padding: theme.spacing(1.625, 0),
          marginRight: theme.spacing(5),
          minHeight: 33,
          minWidth: "auto",
          "&.Mui-selected": {
            color: "#212B36",
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        root: {
          "& .MuiBackdrop-root": {
            background:
              "linear-gradient(256.42deg, #161C24 0%, rgba(22, 28, 36, 0.48) 91.29%);",
          },
        },
        paper: {
          borderRadius: 16,
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: theme.spacing(3),
        },
      },
    },
    MuiDialogActions: {
      defaultProps: {
        disableSpacing: true,
      },
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 3, 3),
          "& > :not(:first-of-type)": {
            marginLeft: theme.spacing(1.5),
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiFormLabel-root": {
            transform: "translate(14px, -9px) scale(0.75)",
            backgroundColor: "#fff",
            paddingLeft: 5,
            paddingRight: 5,
            width: "auto",
          },
        },
      },
    },
  };
};
