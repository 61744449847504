import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'forgot-password-form'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      minWidth: 488,
      width: 488,
      padding: 32
    },
    header: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 40
    },
    title: {
      fontSize: 24,
      lineHeight: 1.166,
      fontWeight: 600,
      marginBottom: 8
    },
    apiErrors: {
      marginTop: 8,
      marginBottom: 24,
      textAlign: 'center',
      color: 'red',
      fontSize: 12
    }
  }),
  options
);

export default useStyles;
