import { PageHeader } from "../../components/page-header";
import { Button, Grid, Stack } from "@mui/material";
import { TableView } from "../../components/table-view";
import {
  ROUTE_TABLES_CREATE,
  ROUTE_TABLES_DETAIL,
  tableColumns,
  url,
} from "../../core";
import { useListResource } from "../../core/hooks/useListResource";
import { fetchGetAllTables } from "../../core/store/slices/tables.slice";
import { Link, useNavigate } from "react-router-dom";
import { FieldAutocomplete } from "../../components/fields/field-autocomplete";
import { useRestaurantsAutocomplete } from "../../core/hooks/useRestaurantsAutocomplete";
import { useMemo } from "react";
import { useFormik } from "formik";

export const TablesListContainer = () => {
  const navigate = useNavigate();
  const { payload, pagination, loading, handlePageChange, handleChangeParams } =
    useListResource(
      {
        embed: "restaurant",
      },
      "tables",
      "findAll",
      fetchGetAllTables
    );

  const { payload: restaurantsPayload, loading: restaurantsLoading } =
    useRestaurantsAutocomplete();

  const restaurantOptions = useMemo(
    () =>
      restaurantsPayload?.results
        ? restaurantsPayload?.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        : [],
    [restaurantsPayload]
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      restaurantId: "",
    },
    onSubmit: (values) => {
      handleChangeParams(values);
    },
  });

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader title="Tables" />
        <Stack direction="row" spacing={2}>
          <Button variant="contained" component={Link} to={ROUTE_TABLES_CREATE}>
            Add new
          </Button>
        </Stack>
      </Grid>
      <Grid container columnSpacing={2} mb={3}>
        <Grid item sm={6}>
          <FieldAutocomplete
            label="Restaurant"
            isLabelGrey
            placeholder="Select"
            loading={restaurantsLoading}
            disabled={restaurantsLoading}
            dataSource={restaurantOptions}
            value={restaurantOptions.find(
              (item) => item.value === values.restaurantId
            )}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue("restaurantId", value.value);
              } else {
                setFieldValue("restaurantId", "");
              }
              handleSubmit();
            }}
          />
        </Grid>
      </Grid>
      <TableView
        showPagination
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) => navigate(url(ROUTE_TABLES_DETAIL, { id }))}
        dataSource={payload?.results || []}
        columns={tableColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
