import { PageHeader } from "../../components/page-header";
import { Grid, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { subscriberColumns } from "../../core";
import { FieldSearch } from "../../components/fields";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import useDebounce from "../../core/hooks/useDebounce";
import { useListResource } from "../../core/hooks/useListResource";
import { fetchGetAllSubscribers } from "../../core/store/slices/subscribers.slice";
import { useCount } from "../../core/hooks/useCount";

export const SubscriberListContainer = () => {
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource({}, "subscribers", "findAll", fetchGetAllSubscribers);
  const { subscriberCount } = useCount();
  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader
          title="Subscribers"
          meta={
            <Typography color="text.disabled" variant="h3" component="span">
              {subscriberCount}
            </Typography>
          }
        ></PageHeader>
      </Grid>
      <Box mt={5} mb={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>
      <TableView
        showPagination
        pagination={pagination}
        onChangePage={handlePageChange}
        dataSource={payload?.results || []}
        columns={subscriberColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
