import React, { FC } from "react";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  Toolbar,
  Typography,
  Box,
} from "@mui/material";
import { map, startsWith } from "lodash";
import { NavLink, useLocation, Link } from "react-router-dom";
import useStyles from "./styles";
import {
  ROUTE_PROFILE,
  ROUTE_DASHBOARD,
  ROUTE_RESTAURANTS,
  ROUTE_WAITERS,
  ROUTE_CLIENTS,
  ROUTE_ORDERS,
  ROUTE_SUBSCRIBERS,
  ROUTE_POS,
  ROUTE_TABLES,
} from "../../../core";
import {
  IconClients,
  IconDashboard,
  IconOrders,
  IconRestaurants,
  IconWaiters,
  IconOmnivore,
  IconTables,
} from "../../icons";

import alacartLogo from "../../../../src/images/logo.png";
import { useUser } from "../../../core/hooks/useUser";
import { getInitials } from "../../../core/http/utils";
import { useCount } from "../../../core/hooks/useCount";

interface SidebarProps {
  width: number;
  mobileOpen: boolean;
  setMobileOpen: (state: boolean) => void;
}

const NAV_ITEMS = ({
  restaurantCount,
  waiterCount,
  clientCount,
  orderCount,
  subscriberCount,
}) => [
  {
    label: "Dashboard",
    path: ROUTE_DASHBOARD,
    icon: <IconDashboard />,
    sx: null,
  },
  {
    label: "Restaurants",
    path: ROUTE_RESTAURANTS,
    icon: <IconRestaurants />,
    sx: null,
    counter: (restaurantCount || 0).toString(),
  },
  {
    label: "Waiters",
    path: ROUTE_WAITERS,
    icon: <IconWaiters />,
    sx: null,
    counter: (waiterCount || 0).toString(),
  },
  {
    label: "Clients",
    path: ROUTE_CLIENTS,
    icon: <IconClients />,
    sx: null,
    counter: (clientCount || 0).toString(),
  },
  {
    label: "Orders",
    path: ROUTE_ORDERS,
    icon: <IconOrders />,
    sx: null,
    counter: (orderCount || 0).toString(),
  },
  {
    label: "Subscribers",
    path: ROUTE_SUBSCRIBERS,
    icon: <IconClients />,
    sx: null,
    counter: (subscriberCount || 0).toString(),
  },
  {
    label: "Tables",
    path: ROUTE_TABLES,
    icon: <IconTables />,
    sx: null,
  },
  {
    label: "POS",
    path: ROUTE_POS,
    icon: <IconOmnivore />,
    sx: null,
  },
];

export const Sidebar: FC<SidebarProps> = ({
  width,
  mobileOpen,
  setMobileOpen,
}) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const { payload, loading } = useUser();
  const {
    restaurantCount,
    waiterCount,
    clientCount,
    orderCount,
    subscriberCount,
  } = useCount();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isSelected = (path: string) => {
    return startsWith(pathname, path);
  };

  const ListItemProps = (path: string): any => {
    return {
      button: true,
      selected: isSelected(path),
      classes: {
        root: classes.navItem,
        selected: classes.navItemSelected,
      },
      component: NavLink,
      to: path,
    };
  };

  const drawer = (
    <>
      <Toolbar className={classes.toolbar}>
        <Link to={ROUTE_DASHBOARD} className={classes.toolbarLink}>
          <h2 className={classes.toolbarLogoText}>
            <img
              className={classes.logoImg}
              src={alacartLogo}
              alt="Alacart Logo"
            />
          </h2>
        </Link>
      </Toolbar>
      <List className={classes.nav} disablePadding component="div">
        {map(
          NAV_ITEMS({
            restaurantCount,
            waiterCount,
            clientCount,
            orderCount,
            subscriberCount,
          }),
          (item, index) => (
            <ListItem
              {...ListItemProps(item.path)}
              sx={item.sx || {}}
              key={index}
            >
              <Box className={classes.navItemIcon}>{item.icon}</Box>
              <Typography variant="body2" component="span">
                {item.label}
              </Typography>
              {item.counter && (
                <Typography
                  variant="subtitle2"
                  component="span"
                  className={classes.navLabel}
                >
                  {item.counter}
                </Typography>
              )}
            </ListItem>
          )
        )}
        {!loading && (
          <ListItem
            className={classes.userNavItem}
            component={NavLink}
            to={ROUTE_PROFILE}
          >
            <Box className={classes.navItemIcon}>
              <Avatar
                alt="Carlota Monteiro"
                src="/assets/images/avatars/1.png"
                className={classes.avatar}
              >
                {getInitials(payload?.fullName)}
              </Avatar>
            </Box>
            <Box>
              <Typography variant="subtitle2" component="div">
                {payload?.fullName}
              </Typography>
              <Typography variant="caption" component="div">
                {payload?.role}
              </Typography>
            </Box>
          </ListItem>
        )}
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { sm: width }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
      className={classes.root}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: width },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: width },
        }}
        open
        classes={{
          root: classes.drawer,
          paper: classes.drawerPaper,
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
