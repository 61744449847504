import { Button, Stack } from "@mui/material";
import { formatCurrency } from "../utils/currency-format.utils";

export const posTicketsColumns = (handleClosePosTicket) => [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Table",
    dataIndex: "table",
    key: "table",
  },
  {
    title: "Temporary code",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    render: (total) => formatCurrency(total),
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
    render: (_, row) => {
      return (
        <Stack direction="row" spacing={1}>
          <Button
            color="primary"
            size="small"
            onClick={() => {
              handleClosePosTicket(row.id);
            }}
          >
            Close ticket
          </Button>
        </Stack>
      );
    },
  },
];
