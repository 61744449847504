import { FC, PropsWithChildren, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import Typography, { TypographyProps } from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import { Divider } from "@mui/material";
import useStyles from "./styles";

interface Props {
  header?: ReactNode;
  title: ReactNode;
  meta?: ReactNode;
  divider?: boolean;
  TitleProps?: TypographyProps;
  RootProps?: BoxProps;
}

export const DetailHeader: FC<PropsWithChildren<Props>> = ({
  header,
  title,
  divider = true,
  meta,
  children,
  TitleProps,
  RootProps,
}) => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root} {...RootProps}>
        {header && header}
        <Stack direction="row" alignItems="center">
          <Typography variant="h4" {...TitleProps}>
            {title}
          </Typography>
          {meta && (
            <Stack direction="row" spacing={1} className={classes.meta}>
              {meta}
            </Stack>
          )}
          <Stack direction="row" spacing={1} className={classes.action}>
            {children}
          </Stack>
        </Stack>
      </Box>
      {divider && <Divider sx={{ mx: -3, mb: 3, mt: 2 }} />}
    </>
  );
};
