import { createSlice } from "@reduxjs/toolkit";
import { RestaurantApi } from "../../http/api/restaurant.api";
import { AsyncState, createFetchAsync } from "../utils";

const restaurantApi = new RestaurantApi();

export const fetchGetAllRestaurantsAsync = createFetchAsync(
  "restaurant",
  "findAll",
  async (params) => {
    try {
      const response = await restaurantApi.getAllRestaurants(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllRestaurants = fetchGetAllRestaurantsAsync.asyncThunk;

export const fetchGetRestaurantAsync = createFetchAsync(
  "restaurant",
  "find",
  async (params) => {
    try {
      const response = await restaurantApi.getRestaurant(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetRestaurant = fetchGetRestaurantAsync.asyncThunk;

export const fetchGetRestaurantBalanceAsync = createFetchAsync(
  "restaurant",
  "balance",
  async (id) => {
    try {
      const response = await restaurantApi.getRestaurantBalance(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetRestaurantBalance =
  fetchGetRestaurantBalanceAsync.asyncThunk;

export const fetchCreateRestaurantAsync = createFetchAsync(
  "restaurant",
  "create",
  async (params) => {
    try {
      const response = await restaurantApi.createRestaurant(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCreateRestaurant = fetchCreateRestaurantAsync.asyncThunk;

export const fetchUpdateRestaurantAsync = createFetchAsync(
  "restaurant",
  "create",
  async ({ id, params }) => {
    try {
      const response = await restaurantApi.updateRestaurant(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchUpdateRestaurant = fetchUpdateRestaurantAsync.asyncThunk;

export const fetchDeleteRestaurantAsync = createFetchAsync(
  "restaurant",
  "delete",
  async (id) => {
    try {
      const response = await restaurantApi.deleteRestaurant(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDeleteRestaurant = fetchDeleteRestaurantAsync.asyncThunk;

export const fetchDeactivateRestaurantAsync = createFetchAsync(
  "restaurant",
  "deactivate",
  async (id) => {
    try {
      const response = await restaurantApi.deactivateRestaurant(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDeactivateRestaurant =
  fetchDeactivateRestaurantAsync.asyncThunk;

export const fetchActivateRestaurantAsync = createFetchAsync(
  "restaurant",
  "activate",
  async (id) => {
    try {
      const response = await restaurantApi.activateRestaurant(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchActivateRestaurant = fetchActivateRestaurantAsync.asyncThunk;

export const fetchGetRestaurantWaitersAsync = createFetchAsync(
  "restaurant",
  "findAllWaiters",
  async ({ id, params }) => {
    try {
      const response = await restaurantApi.getRestaurantWaiters(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetRestaurantWaiters =
  fetchGetRestaurantWaitersAsync.asyncThunk;

export const fetchConnectWaiterRestaurantAsync = createFetchAsync(
  "restaurant",
  "connectWaiter",
  async ({ restaurantId, waiterId }) => {
    try {
      const response = await restaurantApi.connectWaiter(
        restaurantId,
        waiterId
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchConnectWaiterRestaurant =
  fetchConnectWaiterRestaurantAsync.asyncThunk;

export const fetchDisconnectWaiterRestaurantAsync = createFetchAsync(
  "restaurant",
  "disconnectWaiter",
  async ({ restaurantId, waiterId }) => {
    try {
      const response = await restaurantApi.disconnectWaiter(
        restaurantId,
        waiterId
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDisconnectWaiterRestaurant =
  fetchDisconnectWaiterRestaurantAsync.asyncThunk;

export const fetchRestaurantPayoutsAsync = createFetchAsync(
  "restaurant",
  "findPayouts",
  async ({ restaurantId, ...params }) => {
    try {
      const response = await restaurantApi.getRestaurantPayouts(
        restaurantId,
        params
      );
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchRestaurantPayouts = fetchRestaurantPayoutsAsync.asyncThunk;

export const fetchAddRestaurantPayoutAsync = createFetchAsync(
  "restaurant",
  "addPayouts",
  async ({ id, values, onSuccess, onError }) => {
    try {
      const response = await restaurantApi.addRestaurantPayout(id, values);
      onSuccess();
      return response.data;
    } catch (error) {
      onError(error.message);
      throw new Error(error);
    }
  }
);
export const fetchAddRestaurantPayout =
  fetchAddRestaurantPayoutAsync.asyncThunk;

export const fetchGetPayoutsStatsAsync = createFetchAsync(
  "restaurant",
  "findPayoutsStats",
  async (params) => {
    try {
      // const response = await restaurantApi.getPayoutsStats(params);
      // return response.data;
      return {
        balance: 1000,
        totalEarned: 1000,
        totalWithdrawn: 1000,
      };
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetPayoutsStats = fetchGetPayoutsStatsAsync.asyncThunk;

export const fetchGetRestaurantsCountAsync = createFetchAsync(
  "restaurant",
  "count",
  async () => {
    try {
      const response = await restaurantApi.getRestaurantCount();
      return response.count;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchGetRestaurantsCount =
  fetchGetRestaurantsCountAsync.asyncThunk;

type RestaurantState = {
  findAll: AsyncState<any>;
  find: AsyncState<any>;
  create: AsyncState<any>;
  update: AsyncState<any>;
  delete: AsyncState<any>;
  deactivate: AsyncState<any>;
  activate: AsyncState<any>;
  findAllWaiters: AsyncState<any>;
  connectWaiter: AsyncState<any>;
  disconnectWaiter: AsyncState<any>;
  findPayouts: AsyncState<any>;
  findPayoutsStats: AsyncState<any>;
  count: AsyncState<any>;
  addPayout: AsyncState<any>;
  balance: AsyncState<any>;
};

const initialState: RestaurantState = {
  findAll: fetchGetAllRestaurantsAsync.initialState,
  find: fetchGetRestaurantAsync.initialState,
  create: fetchCreateRestaurantAsync.initialState,
  update: fetchUpdateRestaurantAsync.initialState,
  delete: fetchDeleteRestaurantAsync.initialState,
  deactivate: fetchDeactivateRestaurantAsync.initialState,
  activate: fetchActivateRestaurantAsync.initialState,
  findAllWaiters: fetchGetRestaurantWaitersAsync.initialState,
  connectWaiter: fetchConnectWaiterRestaurantAsync.initialState,
  disconnectWaiter: fetchDisconnectWaiterRestaurantAsync.initialState,
  findPayouts: fetchRestaurantPayoutsAsync.initialState,
  findPayoutsStats: fetchGetPayoutsStatsAsync.initialState,
  count: fetchGetRestaurantsCountAsync.initialState,
  addPayout: fetchAddRestaurantPayoutAsync.initialState,
  balance: fetchGetRestaurantBalanceAsync.initialState,
};

export const restaurantsSlice = createSlice({
  name: "restaurantsSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllRestaurantsAsync.extraReducers,
    ...fetchGetRestaurantAsync.extraReducers,
    ...fetchCreateRestaurantAsync.extraReducers,
    ...fetchUpdateRestaurantAsync.extraReducers,
    ...fetchDeleteRestaurantAsync.extraReducers,
    ...fetchDeactivateRestaurantAsync.extraReducers,
    ...fetchActivateRestaurantAsync.extraReducers,
    ...fetchGetRestaurantWaitersAsync.extraReducers,
    ...fetchConnectWaiterRestaurantAsync.extraReducers,
    ...fetchDisconnectWaiterRestaurantAsync.extraReducers,
    ...fetchRestaurantPayoutsAsync.extraReducers,
    ...fetchGetPayoutsStatsAsync.extraReducers,
    ...fetchGetRestaurantsCountAsync.extraReducers,
    ...fetchAddRestaurantPayoutAsync.extraReducers,
    ...fetchGetRestaurantBalanceAsync.extraReducers,
  },
});

export default restaurantsSlice.reducer;
