import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { fetchGetAllRestaurants } from "../store/slices/restaurants.slice";

export const useRestaurantsAutocomplete = () => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.restaurants.findAll
  );
  const fetchData = useCallback(() => {
    dispatch(
      fetchGetAllRestaurants({
        limit: 100,
        page: 1,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (payload?.results?.length) {
      return;
    }
    fetchData();
  }, [fetchData, payload?.results?.length]);

  return { payload, errors, loading };
};
