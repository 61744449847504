import { FieldSearch } from "../../../components/fields";
import { TableView } from "../../../components/table-view";
import { Box } from "@mui/system";
import { restaurantWaiterColumns } from "../../../core";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useDebounce from "../../../core/hooks/useDebounce";
import { useListResource } from "../../../core/hooks/useListResource";
import { fetchGetAllWaiters } from "../../../core/store/slices/waiters.slice";

export const Waiters = () => {
  const { id } = useParams();
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        restaurantId: id,
        embed: "ordersCount,totalIncome",
      },
      "waiters",
      "findAll",
      fetchGetAllWaiters
    );

  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Box mb={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>

      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        dataSource={payload?.results || []}
        columns={restaurantWaiterColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
