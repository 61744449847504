import { createAction, createSlice } from "@reduxjs/toolkit";

function withPayloadType<T>() {
  return (t: T) => ({ payload: t });
}

export const fetchCounts = createAction(
  "fetchCountsAction",
  withPayloadType<boolean>()
);

type AppState = {
  countsFetched: boolean;
};

const initialState: AppState = {
  countsFetched: false,
};

export const appSlice = createSlice({
  name: "appSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCounts, (state, { payload }) => {
      state.countsFetched = payload;
    });
  },
});

export default appSlice.reducer;
