import { useNavigate } from 'react-router-dom';
import { ROUTE_DASHBOARD } from '../../core';
import { SignInForm } from './sign-in-form/SignInForm';
import { useLogin } from '../../core/hooks/useLogin';

export const SignInContainer = () => {
  const navigate = useNavigate();

  const { handleSubmit, getFieldProps, errors, loading } = useLogin({
    onSuccess: () => {
      navigate(ROUTE_DASHBOARD);
    }
  });

  return <SignInForm {...{ handleSubmit, getFieldProps, errors, loading }} />;
};
