import { createSlice } from "@reduxjs/toolkit";
import { WaiterApi } from "../../http/api/waiter.api";
import { AsyncState, createFetchAsync } from "../utils";

const waiterApi = new WaiterApi();

export const fetchGetAllWaitersAsync = createFetchAsync(
  "waiter",
  "findAll",
  async (params) => {
    try {
      const response = await waiterApi.getAllWaiters(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllWaiters = fetchGetAllWaitersAsync.asyncThunk;

export const fetchGetWaiterAsync = createFetchAsync(
  "waiter",
  "find",
  async (params) => {
    try {
      const response = await waiterApi.getWaiter(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetWaiter = fetchGetWaiterAsync.asyncThunk;

export const fetchCreateWaiterAsync = createFetchAsync(
  "waiter",
  "create",
  async (params) => {
    try {
      const response = await waiterApi.createWaiter(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCreateWaiter = fetchCreateWaiterAsync.asyncThunk;

export const fetchUpdateWaiterAsync = createFetchAsync(
  "waiter",
  "create",
  async ({ id, params }) => {
    try {
      const response = await waiterApi.updateWaiter(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchUpdateWaiter = fetchUpdateWaiterAsync.asyncThunk;

export const fetchDeactivateWaiterAsync = createFetchAsync(
  "waiter",
  "deactivate",
  async (id) => {
    try {
      const response = await waiterApi.deactivateWaiter(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchDeactivateWaiter = fetchDeactivateWaiterAsync.asyncThunk;

export const fetchActivateWaiterAsync = createFetchAsync(
  "waiter",
  "activate",
  async (id) => {
    try {
      const response = await waiterApi.activateWaiter(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);
export const fetchActivateWaiter = fetchActivateWaiterAsync.asyncThunk;

export const fetchGetWaiterCountAsync = createFetchAsync(
  "waiter",
  "count",
  async () => {
    try {
      const response = await waiterApi.getWaiterCount();
      return response.count;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetWaiterCount = fetchGetWaiterCountAsync.asyncThunk;

type WaiterState = {
  findAll: AsyncState<any>;
  find: AsyncState<any>;
  create: AsyncState<any>;
  update: AsyncState<any>;
  deactivate: AsyncState<any>;
  activate: AsyncState<any>;
  count: AsyncState<any>;
};

const initialState: WaiterState = {
  findAll: fetchGetAllWaitersAsync.initialState,
  find: fetchGetWaiterAsync.initialState,
  create: fetchCreateWaiterAsync.initialState,
  update: fetchUpdateWaiterAsync.initialState,
  deactivate: fetchDeactivateWaiterAsync.initialState,
  activate: fetchActivateWaiterAsync.initialState,
  count: fetchGetWaiterCountAsync.initialState,
};

export const waitersSlice = createSlice({
  name: "waitersSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllWaitersAsync.extraReducers,
    ...fetchGetWaiterAsync.extraReducers,
    ...fetchCreateWaiterAsync.extraReducers,
    ...fetchUpdateWaiterAsync.extraReducers,
    ...fetchDeactivateWaiterAsync.extraReducers,
    ...fetchActivateWaiterAsync.extraReducers,
    ...fetchGetWaiterCountAsync.extraReducers,
  },
});

export default waitersSlice.reducer;
