import { format } from "date-fns";
import { DISPLAY_DATE_FORMAT } from "../constants";

export const tableColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Restaurant",
    dataIndex: "restaurant.name",
    key: "restaurantId",
  },
  { title: "Alias", dataIndex: "alias", key: "alias" },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Number",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "Seats",
    dataIndex: "seats",
    key: "seats",
  },
  {
    title: "Date/time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (v) => format(new Date(v), DISPLAY_DATE_FORMAT),
  },
];
