import { Button, Stack, TextField, Typography } from "@mui/material";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate, useParams } from "react-router-dom";
import { DISPLAY_DATE_FORMAT, ROUTE_TABLES } from "../../core";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { useTablesForm } from "../../core/hooks/useTablesForm";
import { format } from "date-fns";
import { useSnackbar } from "../../core/hooks/useSnackbar";
import { useRestaurantsAutocomplete } from "../../core/hooks/useRestaurantsAutocomplete";
import { useMemo } from "react";
import { FieldAutocomplete } from "../../components/fields/field-autocomplete";

export const TablesFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;
  const { showMessage } = useSnackbar();
  const { payload: restaurantsPayload, loading: restaurantsLoading } =
    useRestaurantsAutocomplete();
  const { getFieldProps, setFieldValue, handleSubmit, values, payload } =
    useTablesForm({
      id,
      onSuccess: () => {
        navigate(ROUTE_TABLES);
      },
      onError: (error) => {
        showMessage(error, "error");
      },
    });

  const restaurantOptions = useMemo(
    () =>
      restaurantsPayload?.results
        ? restaurantsPayload?.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        : [],
    [restaurantsPayload]
  );

  return (
    <>
      <DetailHeader
        title={isEdit ? payload?.alias : "Add new table"}
        header={
          <Breadcrumbs
            items={[
              { label: "Tables", url: ROUTE_TABLES },
              { label: isEdit ? payload?.alias : "Add new table" },
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_TABLES)}
        >
          Cancel
        </Button>
      </DetailHeader>
      {isEdit && (
        <Stack direction="row" spacing={3} mb={3}>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Created:
            </Typography>
            <Typography variant="body2">
              {payload?.createdAt
                ? format(new Date(payload.createdAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Updated:
            </Typography>
            <Typography variant="body2">
              {payload?.updatedAt
                ? format(new Date(payload.updatedAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack spacing={3} mb={6}>
        <FieldAutocomplete
          label="Restaurant"
          isLabelGrey
          placeholder="Select"
          loading={restaurantsLoading}
          disabled={restaurantsLoading}
          dataSource={restaurantOptions}
          error={!!getFieldProps("restaurantId").error}
          errorMessage="Required field"
          value={restaurantOptions.find(
            (item) => item.value === values.restaurantId
          )}
          onChange={(value: any) => {
            if (value?.value) {
              setFieldValue("restaurantId", value.value);
            } else {
              setFieldValue("restaurantId", "");
            }
          }}
        />
        <TextField label="Alias" {...getFieldProps("alias")} />
        <TextField label="Name" {...getFieldProps("name")} />
        <TextField label="Number" {...getFieldProps("number")} />
        <TextField label="Seats" {...getFieldProps("seats")} />
      </Stack>
    </>
  );
};
