import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { fetchGetClient } from '../store/slices/clients.slice';

export const useClient = (id) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.clients.find
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetClient(id));
  }, [id, dispatch]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return { payload, errors, loading };
};
