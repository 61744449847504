import { Button, Stack, TextField } from "@mui/material";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTE_POS } from "../../core";
import { usePosForm } from "../../core/hooks/usePosForm";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { FieldSelect } from "../../components/fields";

export const PosFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;
  const { getFieldProps, handleSubmit, values } = usePosForm({
    id,
    onSuccess: () => {
      navigate(ROUTE_POS);
    },
  });

  return (
    <>
      <DetailHeader
        title={isEdit ? values?.name : "Add new POS ticket"}
        header={
          <Breadcrumbs
            items={[
              { label: "POS tickets", url: ROUTE_POS },
              {
                label: isEdit ? values?.name : "Add new POS ticket",
              },
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_POS)}
        >
          Cancel
        </Button>
      </DetailHeader>

      <Stack spacing={3} mb={6}>
        <TextField label="Temporary code" {...getFieldProps("name")} />
        <FieldSelect
          label="Employee"
          items={[
            {
              label: "John Doe",
              value: "100",
            },
            {
              label: "Jane Dough",
              value: "200",
            },
          ]}
          {...getFieldProps("employee")}
        />
        <FieldSelect
          label="Table"
          items={[
            {
              label: "1",
              value: "1",
            },
            {
              label: "2",
              value: "2",
            },
            {
              label: "3",
              value: "3",
            },
          ]}
          {...getFieldProps("table")}
        />
      </Stack>
    </>
  );
};
