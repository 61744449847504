import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { fetchGetTable } from "../store/slices/tables.slice";

export const useTable = (id) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.tables.find
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetTable(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return { payload, errors, loading };
};
