import { Button, Stack, TextField, Typography } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate, useParams } from "react-router-dom";
import {
  DISPLAY_DATE_FORMAT,
  restaurantStatusState,
  ROUTE_RESTAURANTS,
} from "../../core";
import { useRestaurantForm } from "../../core/hooks/useRestaurantForm";
import { FieldPhone } from "../../components/fields";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { format } from "date-fns";

export const RestaurantFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;
  const { getFieldProps, handleSubmit, values, setFieldValue } =
    useRestaurantForm({
      id,
      onSuccess: () => {
        navigate(ROUTE_RESTAURANTS);
      },
    });

  return (
    <>
      <DetailHeader
        title={isEdit ? values?.name : "Add new restaurant"}
        header={
          <Breadcrumbs
            items={[
              { label: "Restaurants", url: ROUTE_RESTAURANTS },
              {
                label: isEdit ? values?.name : "Add new restaurant",
              },
            ]}
          />
        }
        meta={
          isEdit && (
            <StatusBadge
              label={values?.isActive ? "Active" : "Inactive"}
              variant={restaurantStatusState(values?.isActive)}
            />
          )
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_RESTAURANTS)}
        >
          Cancel
        </Button>
      </DetailHeader>
      {isEdit && (
        <Stack direction="row" spacing={3} mb={3}>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Created:
            </Typography>
            <Typography variant="body2">
              {values.createdAt
                ? format(new Date(values.createdAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Updated:
            </Typography>
            <Typography variant="body2">
              {values.updatedAt
                ? format(new Date(values.updatedAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack spacing={3} mb={6}>
        <TextField label="Name" {...getFieldProps("name")} />
        <FieldPhone label="Phone" {...getFieldProps("phone")} />
        <TextField label="E-mail" {...getFieldProps("email")} />
        <TextField label="Address" {...getFieldProps("address")} />
        <TextField label="Wifi SsId" {...getFieldProps("wifiSsid")} />
        <TextField label="Wifi Password" {...getFieldProps("wifiPassword")} />
        <TextField
          label="Notes"
          multiline
          rows={3}
          {...getFieldProps("notes")}
        />
      </Stack>
      <Typography variant="h6" mb={3}>
        Withdrawal details
      </Typography>
      <Stack spacing={3} mb={6}>
        <TextField label="Sort number" {...getFieldProps("sortCode")} />
        <TextField label="Account number" {...getFieldProps("accountNumber")} />
        <TextField label="Postal code" {...getFieldProps("postCode")} />
      </Stack>
      <Typography variant="h6" mb={3}>
        POS configs
      </Typography>
      <Stack spacing={3} mb={6}>
        <TextField
          label="Omnivore ID"
          {...getFieldProps("omnivoreLocationId")}
          onChange={(e) => {
            setFieldValue("omnivoreLocationId", e.target.value);
            setFieldValue("comtrexUrl", "");
            setFieldValue("comtrexLogin", "");
            setFieldValue("comtrexPassword", "");
          }}
        />
        <TextField
          label="Comtrex Url "
          {...getFieldProps("comtrexUrl")}
          onChange={(e) => {
            setFieldValue("comtrexUrl", e.target.value);
            setFieldValue("omnivoreLocationId", "");
          }}
        />
        <TextField
          label="Comtrex Login"
          {...getFieldProps("comtrexLogin")}
          onChange={(e) => {
            setFieldValue("comtrexLogin", e.target.value);
            setFieldValue("omnivoreLocationId", "");
          }}
        />
        <TextField
          label="Comtrex Password"
          {...getFieldProps("comtrexPassword")}
          onChange={(e) => {
            setFieldValue("comtrexPassword", e.target.value);
            setFieldValue("omnivoreLocationId", "");
          }}
        />
      </Stack>
    </>
  );
};
