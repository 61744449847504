import { combineReducers } from "redux";
import appReducer from "./slices/app.slice";
import authReducer from "./slices/auth.slice";
import restaurantsReducer from "./slices/restaurants.slice";
import waitersReducer from "./slices/waiters.slice";
import clientsReducer from "./slices/clients.slice";
import ordersReducer from "./slices/orders.slice";
import subscribersReducer from "./slices/subscribers.slice";
import posReducer from "./slices/pos.slice";
import tablesReducer from "./slices/tables.slice";

export const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  restaurants: restaurantsReducer,
  waiters: waitersReducer,
  clients: clientsReducer,
  orders: ordersReducer,
  subscribers: subscribersReducer,
  pos: posReducer,
  tables: tablesReducer,
});
