import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { fetchCreateTransaction } from "../store/slices/orders.slice";

interface UseProps {
  id: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useMakeTransactionForm = ({
  id,
  onSuccess,
  onError,
}: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      type: "",
      amount: "",
      comment: "",
    },
    validationSchema: yup.object().shape({
      type: yup.string().required("Required field"),
      amount: yup
        .number()
        .min(1, "Amount must be greater then 1")
        .required("Required field"),
    }),
    onSubmit: async (values) => {
      const formattedValues = { ...values, amount: +values.amount * 100 };
      await dispatch(
        fetchCreateTransaction({
          id,
          values: formattedValues,
          onSuccess: () => {
            onSuccess();
          },
          onError: (m) => {
            onError(m);
          },
        })
      );
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        const targetType = get(e, "target.type");
        const targetMin = get(e, "target.min");
        if (
          targetType === "number" &&
          !isNaN(+targetMin) &&
          +targetValue < +targetMin
        ) {
          setFieldValue(fieldName, "");
        } else if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
