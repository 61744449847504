export const ROUTE_HOME = "/";
export const ROUTE_SIGN_IN = "/sign-in";
export const ROUTE_FORGOT_PASSWORD = "/forgot-password";
export const ROUTE_DASHBOARD = "/dashboard";

export const ROUTE_RESTAURANTS = "/restaurants";
export const ROUTE_RESTAURANTS_DETAIL = "/restaurants/view/:id";
export const ROUTE_RESTAURANTS_CREATE = "/restaurants/create";
export const ROUTE_RESTAURANTS_EDIT = "/restaurants/edit/:id";

export const ROUTE_WAITERS = "/waiters";
export const ROUTE_WAITERS_DETAIL = "/waiters/view/:id";
export const ROUTE_WAITERS_CREATE = "/waiters/create";
export const ROUTE_WAITERS_EDIT = "/waiters/edit/:id";

export const ROUTE_CLIENTS = "/clients";
export const ROUTE_CLIENTS_DETAIL = "/clients/view/:id";

export const ROUTE_ORDERS = "/orders";
export const ROUTE_ORDERS_DETAIL = "/orders/view/:orderId";

export const ROUTE_PROFILE = "/profile";
export const ROUTE_PROFILE_EDIT = "/profile/edit";

export const ROUTE_SUBSCRIBERS = "/subscribers";

export const ROUTE_POS = "/pos";
export const ROUTE_POS_TICKET_CREATE = "/pos/create";

export const ROUTE_TABLES = "/tables";
export const ROUTE_TABLES_DETAIL = "/tables/view/:id";
export const ROUTE_TABLES_EDIT = "/tables/edit/:id";
export const ROUTE_TABLES_CREATE = "/tables/create";
