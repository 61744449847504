import { alpha } from '@mui/material';
import { PaletteOptions } from '@mui/material/styles/createPalette';

const primary = '#5B8F90';
const secondary = '#DFE3E8';
const info = '#1890FF';
const success = '#54D62C';
const warning = '#FFC107';
const error = '#FF4842';
const black = '#000';
const white = '#fff';

export const lightPalette: PaletteOptions = {
  background: {
    default: white,
    paper: white
  },
  common: {
    black,
    white
  },
  text: {
    primary: '#212B36',
    secondary: '#637381',
    disabled: '#919EAB'
  },
  primary: {
    contrastText: white,
    light: '#4F7DEA',
    main: primary,
    dark: '#1C4AB7'
  },
  secondary: {
    contrastText: '#212B36',
    light: 'rgba(255, 255, 255, 0.16)',
    main: secondary,
    dark: 'rgba(255, 255, 255, 0.12)'
  },
  info: {
    contrastText: white,
    light: '#74CAFF',
    main: info,
    dark: '#0C53B7'
  },
  success: {
    contrastText: '#131730',
    light: '#AAF27F',
    main: success,
    dark: '#229A16'
  },
  warning: {
    contrastText: '#131730',
    light: '#FFE16A',
    main: warning,
    dark: '#B78103'
  },
  error: {
    contrastText: white,
    light: '#FFA48D',
    main: error,
    dark: '#B72136'
  },
  action: {
    disabledBackground: 'rgba(145, 158, 171, 0.24)',
    disabled: 'rgba(145, 158, 171, 0.8)'
  },
  divider: 'rgba(145, 158, 171, 0.24)',
  grey: {
    50: alpha('#222222', 0.05),
    100: alpha('#222222', 0.1),
    200: alpha('#222222', 0.2),
    300: '#DFE3E8',
    400: alpha('#222222', 0.4),
    500: alpha('#222222', 0.5),
    600: alpha('#222222', 0.6),
    700: alpha('#222222', 0.7),
    800: '#212B36',
    900: alpha('#222222', 0.9)
  }
};
