import { format } from "date-fns";
import { StatusBadge } from "../../components/badges";
import { DISPLAY_DATE_FORMAT, payoutStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const payoutColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date/time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (v) => format(new Date(v), DISPLAY_DATE_FORMAT),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    key: "createdBy",
    render: (createdBy) => createdBy?.fullName || "-",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => formatCurrency(amount),
  },
  {
    title: "Balance",
    dataIndex: "balanceAfter",
    key: "balanceAfter",
    render: (balanceAfter) => formatCurrency(balanceAfter),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) => (
      <StatusBadge label={status} variant={payoutStatusState(status)} />
    ),
  },
];
