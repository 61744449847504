import { Button, CircularProgress, Grid } from "@mui/material";
import { FieldSearch } from "../../../components/fields";
import { StatsCard } from "../../../components/stats-card";
import { TableView } from "../../../components/table-view";
import { Box } from "@mui/system";
import { incomeColumns, ROUTE_ORDERS_DETAIL, url } from "../../../core";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { useEffect, useState } from "react";
import useDebounce from "../../../core/hooks/useDebounce";
import { useListResource } from "../../../core/hooks/useListResource";
import {
  fetchGetAllOrders,
  fetchGetOrdersStats,
} from "../../../core/store/slices/orders.slice";
import { useStatsResource } from "../../../core/hooks/useStatsResource";
import qs from "qs";
import { setISODay } from "date-fns";

export const Orders = () => {
  const navigate = useNavigate();
  const { id: waiterId } = useParams();
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { payload: statsPayload, loading: statsLoading } = useStatsResource(
    { waiterId },
    "orders",
    "findStats",
    fetchGetOrdersStats
  );
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        waiterId,
        embed: "client,restaurant",
        order: "createdAt",
      },
      "orders",
      "findAll",
      fetchGetAllOrders
    );

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);

    const queryString = qs.stringify(
      { embed: "restaurant,waiter,client", waiterId },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/orders/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "orders.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);
  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item sm={3}>
          <StatsCard
            title="Total Income"
            value={
              statsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(statsPayload?.totalIncome)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={3}>
          <StatsCard
            title="Total Orders"
            value={
              statsLoading ? (
                <CircularProgress size={25} />
              ) : (
                statsPayload?.ordersCount
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={3}>
          <StatsCard
            title="Income (last month)"
            value={
              statsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(statsPayload?.incomeLastMonth)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={3}>
          <StatsCard
            title="Orders (last month)"
            value={
              statsLoading ? (
                <CircularProgress size={25} />
              ) : (
                statsPayload?.ordersLastMonth
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={10}>
          <Box my={2.5}>
            <FieldSearch
              size="small"
              onChange={(value) => {
                setSearchTouched(true);
                setSearch(value);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={2} justifyContent="flex-end" display="flex">
          <Button
            color="secondary"
            sx={{ height: 36 }}
            onClick={() => handleExport()}
            size="small"
          >
            Export
          </Button>
        </Grid>
      </Grid>
      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) =>
          navigate(url(ROUTE_ORDERS_DETAIL, { orderId: id }))
        }
        dataSource={payload?.results || []}
        columns={incomeColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
