import React from "react";
import { Routes as RouterRoutes, Route } from "react-router-dom";
import {
  SignInPage,
  DashboardIndex,
  ForgotPasswordPage,
  RestaurantsIndex,
  RestaurantsDetail,
  RestaurantsCreate,
  RestaurantsEdit,
  WaitersIndex,
  WaitersDetail,
  WaitersCreate,
  WaitersEdit,
  ClientsIndex,
  ClientsDetail,
  OrdersIndex,
  OrdersDetail,
  ProfileIndex,
  ProfileEdit,
  SubscribersIndex,
  PosIndex,
  PosTicketCreate,
  TablesIndex,
  TablesDetail,
  TablesEdit,
  TablesCreate,
} from "./pages";
import {
  ROUTE_CLIENTS,
  ROUTE_CLIENTS_DETAIL,
  ROUTE_DASHBOARD,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_ORDERS,
  ROUTE_ORDERS_DETAIL,
  ROUTE_PROFILE,
  ROUTE_PROFILE_EDIT,
  ROUTE_RESTAURANTS,
  ROUTE_RESTAURANTS_CREATE,
  ROUTE_RESTAURANTS_DETAIL,
  ROUTE_RESTAURANTS_EDIT,
  ROUTE_SIGN_IN,
  ROUTE_WAITERS,
  ROUTE_WAITERS_CREATE,
  ROUTE_WAITERS_DETAIL,
  ROUTE_WAITERS_EDIT,
  ROUTE_SUBSCRIBERS,
  ROUTE_POS,
  ROUTE_POS_TICKET_CREATE,
  ROUTE_TABLES,
  ROUTE_TABLES_DETAIL,
  ROUTE_TABLES_EDIT,
  ROUTE_TABLES_CREATE,
} from "./core";

const Routes = () => {
  return (
    <RouterRoutes>
      <Route path={ROUTE_HOME} element={<SignInPage />} />
      <Route path={ROUTE_SIGN_IN} element={<SignInPage />} />
      <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
      <Route path={ROUTE_DASHBOARD} element={<DashboardIndex />} />

      <Route path={ROUTE_RESTAURANTS} element={<RestaurantsIndex />} />
      <Route path={ROUTE_RESTAURANTS_DETAIL} element={<RestaurantsDetail />} />
      <Route path={ROUTE_RESTAURANTS_CREATE} element={<RestaurantsCreate />} />
      <Route path={ROUTE_RESTAURANTS_EDIT} element={<RestaurantsEdit />} />

      <Route path={ROUTE_WAITERS} element={<WaitersIndex />} />
      <Route path={ROUTE_WAITERS_DETAIL} element={<WaitersDetail />} />
      <Route path={ROUTE_WAITERS_CREATE} element={<WaitersCreate />} />
      <Route path={ROUTE_WAITERS_EDIT} element={<WaitersEdit />} />

      <Route path={ROUTE_CLIENTS} element={<ClientsIndex />} />
      <Route path={ROUTE_CLIENTS_DETAIL} element={<ClientsDetail />} />

      <Route path={ROUTE_ORDERS} element={<OrdersIndex />} />
      <Route path={ROUTE_ORDERS_DETAIL} element={<OrdersDetail />} />
      <Route path={ROUTE_PROFILE} element={<ProfileIndex />} />
      <Route path={ROUTE_PROFILE_EDIT} element={<ProfileEdit />} />
      <Route path={ROUTE_SUBSCRIBERS} element={<SubscribersIndex />} />
      <Route path={ROUTE_POS} element={<PosIndex />} />
      <Route path={ROUTE_POS_TICKET_CREATE} element={<PosTicketCreate />} />
      <Route path={ROUTE_TABLES} element={<TablesIndex />} />
      <Route path={ROUTE_TABLES_DETAIL} element={<TablesDetail />} />
      <Route path={ROUTE_TABLES_EDIT} element={<TablesEdit />} />
      <Route path={ROUTE_TABLES_CREATE} element={<TablesCreate />} />
    </RouterRoutes>
  );
};

function App() {
  return <Routes />;
}

export default App;
