import React, { ComponentType, useState, useRef } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { IconVisibility, IconVisibilityOff } from "../../icons";

export const PasswordField: ComponentType<TextFieldProps> = ({ ...props }) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    if (passwordRef.current) {
      setTimeout(() => {
        passwordRef.current.selectionStart = passwordRef.current.value.length;
      });
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <TextField
      inputRef={passwordRef}
      {...props}
      InputProps={{
        type: showPassword ? "text" : "password",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <IconVisibilityOff /> : <IconVisibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
