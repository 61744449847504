import { FieldSearch } from "../../../components/fields";
import { TableView } from "../../../components/table-view";
import { Box } from "@mui/system";
import { Link } from "@mui/material";
import { useDialog } from "../../../core/hooks/useDialog";
import { waiterRestaurantColumns } from "../../../core";
import { ConfirmDialog } from "../../../components/dialogs";
import { useListResource } from "../../../core/hooks/useListResource";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  fetchDisconnectWaiterRestaurant,
  fetchGetAllRestaurants,
} from "../../../core/store/slices/restaurants.slice";
import { useEffect, useState } from "react";
import useDebounce from "../../../core/hooks/useDebounce";

export const Restaurants = () => {
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        waiterId: id,
        embed: "waiters,ordersCount,totalIncome",
      },
      "restaurants",
      "findAll",
      fetchGetAllRestaurants
    );

  const handleDisconnect = (restaurantId) => {
    openConfirm({
      title: "Disconnect Restaurant",
      message: "Are you sure you want to disconnect this restaurant?",
      onConfirm: async () => {
        await dispatch(
          fetchDisconnectWaiterRestaurant({ waiterId: id, restaurantId })
        );
        closeConfirm();
      },
    });
  };

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Box mb={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>

      <TableView
        pagination={pagination}
        dataSource={payload?.results || []}
        onChangePage={handlePageChange}
        columns={[
          ...waiterRestaurantColumns(id),
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (_, row) => (
              <Link onClick={() => handleDisconnect(row.id)}>Disconnect</Link>
            ),
          },
        ]}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
