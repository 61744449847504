import { createSlice } from "@reduxjs/toolkit";
import { PosApi } from "../../http/api/pos.api";
import { AsyncState, createFetchAsync } from "../utils";

const posApi = new PosApi();

export const fetchGetAllPosTicketsAsync = createFetchAsync(
  "pos",
  "findAll",
  async (params) => {
    try {
      const response = await posApi.getAllPosTickets(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllPosTickets = fetchGetAllPosTicketsAsync.asyncThunk;

export const fetchCreatePosTicketAsync = createFetchAsync(
  "pos",
  "create",
  async (params) => {
    try {
      const response = await posApi.createPosTicket(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCreatePosTicket = fetchCreatePosTicketAsync.asyncThunk;

export const fetchCloseAllPosTicketsAsync = createFetchAsync(
  "pos",
  "closeAll",
  async () => {
    try {
      const response = await posApi.closeAllPosTickets();
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCloseAllPosTickets = fetchCloseAllPosTicketsAsync.asyncThunk;

export const fetchAddItemsToPosTicketAsync = createFetchAsync(
  "pos",
  "addItems",
  async (params) => {
    try {
      const response = await posApi.addItemsToPosTicket(params.id);
      return response.data;
    } catch (error) {
      console.log(error);
      throw new Error(error);
    }
  }
);

export const fetchAddItemsToPosTicket =
  fetchAddItemsToPosTicketAsync.asyncThunk;

export const fetchClosePosTicketAsync = createFetchAsync(
  "pos",
  "close",
  async (params) => {
    try {
      const response = await posApi.closePosTicket(params.id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchClosePosTicket = fetchClosePosTicketAsync.asyncThunk;

type PosState = {
  findAll: AsyncState<any>;
  create: AsyncState<any>;
  closeAll: AsyncState<any>;
  addItems: AsyncState<any>;
  close: AsyncState<any>;
};

const initialState: PosState = {
  findAll: fetchGetAllPosTicketsAsync.initialState,
  create: fetchCreatePosTicketAsync.initialState,
  closeAll: fetchCloseAllPosTicketsAsync.initialState,
  addItems: fetchAddItemsToPosTicketAsync.initialState,
  close: fetchClosePosTicketAsync.initialState,
};

export const posSlice = createSlice({
  name: "posSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllPosTicketsAsync.extraReducers,
    ...fetchCreatePosTicketAsync.extraReducers,
    ...fetchCloseAllPosTicketsAsync.extraReducers,
    ...fetchAddItemsToPosTicketAsync.extraReducers,
    ...fetchClosePosTicketAsync.extraReducers,
  },
});

export default posSlice.reducer;
