import React, { ComponentType, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  TextField,
  TextFieldProps
} from '@mui/material';
import { IconSearch } from '../../icons';

interface Props extends Omit<TextFieldProps, 'onChange' | 'variant'> {
  onChange?: (value: string) => void;
  loading?: boolean;
}

export const FieldSearch: ComponentType<Props> = ({
  onChange,
  loading,
  ...props
}) => {
  const [value, setValue] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    onChange && onChange(value);
    setValue(value);
  };

  return (
    <TextField
      {...props}
      onChange={handleChange}
      placeholder="Search..."
      value={value}
      InputProps={{
        startAdornment: loading ? (
          <InputAdornment position="start">
            <CircularProgress size={15} />
          </InputAdornment>
        ) : (
          <InputAdornment position="start">
            <IconSearch fontSize="small" />
          </InputAdornment>
        )
      }}
    />
  );
};
