import { PageHeader } from "../../components/page-header";
import { FieldRadioGroup, FieldDateRange } from "../../components/fields";
import { Grid, Stack, Box, CircularProgress } from "@mui/material";
import { StatsCard } from "../../components/stats-card";
import { useFormik } from "formik";
import { DateRange } from "react-day-picker";
import { useEffect, useMemo, useState } from "react";
import { useRestaurantsAutocomplete } from "../../core/hooks/useRestaurantsAutocomplete";
import { useWaitersAutocomplete } from "../../core/hooks/useWaitersAutocomplete";
import { startOfDay, endOfDay, sub } from "date-fns";
import { formatCurrency } from "../../core/utils/currency-format.utils";
import { FieldAutocomplete } from "../../components/fields/field-autocomplete";
import { useStatsResource } from "../../core/hooks/useStatsResource";
import {
  fetchGetOrdersStats,
  fetchGetTransactionStats,
} from "../../core/store/slices/orders.slice";
import { fetchGetClientsStats } from "../../core/store/slices/clients.slice";

export const DashboardContainer = () => {
  const [range, setRange] = useState<DateRange | undefined>({
    from: startOfDay(new Date()),
    to: endOfDay(new Date()),
  });

  const { payload: restaurantsPayload, loading: restaurantsLoading } =
    useRestaurantsAutocomplete();
  const { payload: waitersPayload, loading: waitersLoading } =
    useWaitersAutocomplete();

  const {
    payload: orderStatsPayload,
    handleChangeParams: handleOrdersStatsChangeParams,
    loading: ordersStatsLoading,
  } = useStatsResource(
    {
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
    },
    "orders",
    "findStats",
    fetchGetOrdersStats
  );

  const {
    payload: transactionStatsPayload,
    handleChangeParams: handleTransactionStatsChangeParams,
    loading: transactionStatsLoading,
  } = useStatsResource(
    {
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
    },
    "orders",
    "findTransactionStats",
    fetchGetTransactionStats
  );

  const {
    payload: clientsStatsPayload,
    loading: clientsStatsLoading,
    handleChangeParams: handleClientsStatsChangeParams,
  } = useStatsResource(
    {
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
    },
    "clients",
    "findStats",
    fetchGetClientsStats
  );

  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      period: "",
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
      restaurantId: "",
      waiterId: "",
    },
    onSubmit: (values) => {
      handleOrdersStatsChangeParams(values);
      handleClientsStatsChangeParams(values);
      handleTransactionStatsChangeParams(values);
    },
  });

  useEffect(() => {
    if (range) {
      setFieldValue("dateFrom", range.from || undefined);
      setFieldValue("dateTo", range.to || undefined);
      setTimeout(() => {
        handleSubmit();
      });
    }
  }, [range, setFieldValue, handleSubmit]);

  const restaurantOptions = useMemo(
    () =>
      restaurantsPayload?.results
        ? restaurantsPayload?.results.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        : [],
    [restaurantsPayload]
  );

  const waiterOptions = useMemo(
    () =>
      waitersPayload?.results
        ? waitersPayload?.results.map((item) => ({
            value: item.id,
            label: item.fullName,
          }))
        : [],
    [waitersPayload]
  );

  return (
    <Box
      bgcolor="#F4F6F8"
      height="100vh"
      sx={{
        margin: "-16px -24px -32px -24px",
        padding: "16px 24px 32px 24px",
      }}
    >
      <PageHeader title="Dashboard" divider={false} />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        direction="row"
        mb={3}
        spacing={2}
        height={56}
      >
        <FieldRadioGroup
          defaultValue="today"
          onChange={(value) => {
            setFieldValue("period", value);
            if (value === "today") {
              setFieldValue("dateFrom", startOfDay(new Date()));
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "last-week") {
              setFieldValue(
                "dateFrom",
                sub(startOfDay(new Date()), { days: 7 })
              );
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "last-month") {
              setFieldValue(
                "dateFrom",
                sub(startOfDay(new Date()), { months: 1 })
              );
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "custom") {
              setFieldValue("dateFrom", undefined);
              setFieldValue("dateTo", undefined);
              setRange(undefined);
            }
            setTimeout(() => {
              handleSubmit();
            });
          }}
          items={[
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last week",
              value: "last-week",
            },
            {
              label: "Last month",
              value: "last-month",
            },
            {
              label: "Custom period",
              value: "custom",
            },
          ]}
        />
        {values.period === "custom" && (
          <FieldDateRange range={range} setRange={setRange} />
        )}
      </Stack>

      <Grid container columnSpacing={2} mb={3}>
        <Grid item sm={6}>
          <FieldAutocomplete
            label="Restaurant"
            isLabelGrey
            placeholder="Select"
            loading={restaurantsLoading}
            disabled={restaurantsLoading}
            dataSource={restaurantOptions}
            value={restaurantOptions.find(
              (item) => item.value === values.restaurantId
            )}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue("restaurantId", value.value);
              } else {
                setFieldValue("restaurantId", "");
              }
              handleSubmit();
            }}
          />
        </Grid>
        <Grid item sm={6}>
          <FieldAutocomplete
            label="Waiter"
            isLabelGrey
            placeholder="Select"
            loading={waitersLoading}
            disabled={waitersLoading}
            dataSource={waiterOptions}
            value={waiterOptions.find((item) => item.value === values.waiterId)}
            onChange={(value: any) => {
              if (value?.value) {
                setFieldValue("waiterId", value.value);
              } else {
                setFieldValue("waiterId", "");
              }
              handleSubmit();
            }}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} mb={2}>
        <Grid item sm={6}>
          <StatsCard
            title="Restaurants income"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.restaurantId ||
                  !values.waiterId ||
                  !values.dateFrom ||
                  !values.dateTo
                    ? "-"
                    : formatCurrency(orderStatsPayload?.totalIncome)}
                </>
              )
            }
          />
        </Grid>
        <Grid item sm={6}>
          <StatsCard
            title="Clients"
            value={
              clientsStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.restaurantId ||
                  !values.waiterId ||
                  !values.dateFrom ||
                  !values.dateTo
                    ? "-"
                    : clientsStatsPayload?.clientsCount}
                </>
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        <Grid item sm={4}>
          <StatsCard
            title="Total orders"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.restaurantId ||
                  !values.waiterId ||
                  !values.dateFrom ||
                  !values.dateTo
                    ? "-"
                    : orderStatsPayload?.ordersCount}
                </>
              )
            }
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Refunded"
            value={
              transactionStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.restaurantId ||
                  !values.waiterId ||
                  !values.dateFrom ||
                  !values.dateTo
                    ? "-"
                    : formatCurrency(transactionStatsPayload?.totalRefund)}
                </>
              )
            }
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Credited"
            value={
              transactionStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.dateFrom || !values.dateTo
                    ? "-"
                    : formatCurrency(transactionStatsPayload?.totalCredit)}
                </>
              )
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
