import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import {
  fetchGetOrder,
  fetchGetOrderItems,
  fetchGetTransactions,
} from "../store/slices/orders.slice";

const DEFAULT_LIMIT = 10;
interface Params {
  limit: number;
  page: number;
}

export const useOrder = (id, filters = {}) => {
  const dispatch = useDispatch();

  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.orders.find
  );

  const {
    payload: itemsPayload,
    errors: itemsErrors,
    loading: itemsLoading,
  } = useSelector((state: AppState) => state.orders.findAllItems);

  const {
    payload: paymentsPayload,
    errors: paymentsErrors,
    loading: paymentsLoading,
  } = useSelector((state: AppState) => state.orders.findTransactions);

  const [params, setParams] = useState<Params>({
    limit: DEFAULT_LIMIT,
    page: 1,
  });

  const itemsPagination = {
    total: payload?.total || 0,
    page: params.page,
    totalPages: (payload?.total || 0) / params.page,
    perPage: 10,
  };

  const itemsHandleChangePage = useCallback(
    (page) => {
      setParams((prev) => ({ ...prev, page: page + 1 }));
    },
    [setParams]
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetOrder({ id, params: filters }));
  }, [id, dispatch]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  const fetchItemsData = useCallback(() => {
    dispatch(fetchGetOrderItems(id));
  }, [dispatch, id]);

  useEffect(() => {
    fetchItemsData();
  }, [params, fetchItemsData]);

  const fetchPaymentsData = useCallback(() => {
    dispatch(fetchGetTransactions({ orderId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    fetchPaymentsData();
  }, [params, fetchPaymentsData]);

  return {
    payload,
    errors,
    loading,
    itemsPayload,
    itemsErrors,
    itemsLoading,
    itemsPagination,
    paymentsPayload,
    paymentsErrors,
    paymentsLoading,
    itemsHandleChangePage,
    fetchData,
  };
};
