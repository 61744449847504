import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "page-header",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      minHeight: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    meta: {
      marginLeft: theme.spacing(1),
    },
    action: {
      marginLeft: "auto",
    },
  }),
  options
);

export default useStyles;
