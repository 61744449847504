import { PageHeader } from "../../components/page-header";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { Link, useNavigate } from "react-router-dom";
import {
  restaurantColumns,
  ROUTE_RESTAURANTS_CREATE,
  ROUTE_RESTAURANTS_DETAIL,
  url,
} from "../../core";
import { useListResource } from "../../core/hooks/useListResource";
import qs from "qs";
import { fetchGetAllRestaurants } from "../../core/store/slices/restaurants.slice";
import { useCount } from "../../core/hooks/useCount";

export const RestaurantListContainer = () => {
  const navigate = useNavigate();
  const { payload, pagination, loading, handlePageChange } = useListResource(
    {
      embed: "waiters,totalIncome",
    },
    "restaurants",
    "findAll",
    fetchGetAllRestaurants
  );
  const { restaurantCount } = useCount();

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);

    const queryString = qs.stringify(
      { embed: "waiters,totalIncome" },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/restaurants/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "restaurants.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader
          title="Restaurants"
          meta={
            <Typography color="text.disabled" variant="h3" component="span">
              {restaurantCount}
            </Typography>
          }
        />
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            component={Link}
            to={ROUTE_RESTAURANTS_CREATE}
          >
            Add new
          </Button>
          <Button
            color="secondary"
            sx={{ height: 36 }}
            onClick={() => handleExport()}
            size="small"
          >
            Export
          </Button>
        </Stack>
      </Grid>
      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) => navigate(url(ROUTE_RESTAURANTS_DETAIL, { id }))}
        dataSource={payload?.results || []}
        columns={restaurantColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
