import { PageHeader } from "../../components/page-header";
import { Button, Grid, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { useNavigate } from "react-router-dom";
import { ROUTE_CLIENTS_DETAIL, url, clientColumns } from "../../core";
import { FieldSearch } from "../../components/fields";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import useDebounce from "../../core/hooks/useDebounce";
import qs from "qs";
import { useListResource } from "../../core/hooks/useListResource";
import { fetchGetAllClients } from "../../core/store/slices/clients.slice";
import { useCount } from "../../core/hooks/useCount";

export const ClientListContainer = () => {
  const navigate = useNavigate();
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        embed: "totalSpent,ordersCount",
      },
      "clients",
      "findAll",
      fetchGetAllClients
    );

  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { clientCount } = useCount();

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);

    const queryString = qs.stringify(
      { embed: "totalSpent,ordersCount" },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/clients/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "clients.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader
          title="Clients"
          meta={
            <Typography color="text.disabled" variant="h3" component="span">
              {clientCount}
            </Typography>
          }
        />
        <Button
          color="secondary"
          sx={{ height: 36 }}
          onClick={() => handleExport()}
          size="small"
        >
          Export
        </Button>
      </Grid>
      <Box mt={5} mb={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>
      <TableView
        showPagination
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) => navigate(url(ROUTE_CLIENTS_DETAIL, { id }))}
        dataSource={payload?.results || []}
        columns={clientColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
