import qs from "qs";
import { url } from "../../utils";
import {
  GET_ALL_POS_TICKETS,
  CREATE_POS_TICKET,
  CLOSE_ALL_POS_TICKETS,
  ADD_ITEMS_TO_POS_TICKET,
  CLOSE_POS_TICKET,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class PosApi extends BaseApi {
  /**
   * Get All Pos Tickets
   */
  public getAllPosTickets(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_POS_TICKETS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create Pos Ticket
   */
  public createPosTicket(params): Promise<any> {
    return this.httpClient
      .post(CREATE_POS_TICKET, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Close all pos tickets
   */
  public closeAllPosTickets(): Promise<any> {
    return this.httpClient
      .post(CLOSE_ALL_POS_TICKETS)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Add items to pos ticket
   */
  public addItemsToPosTicket(id): Promise<any> {
    return this.httpClient
      .post(url(ADD_ITEMS_TO_POS_TICKET, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Close all pos tickets
   */
  public closePosTicket(id): Promise<any> {
    return this.httpClient
      .post(url(CLOSE_POS_TICKET, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
