import { FC } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import useStyles from './styles';
import { Link } from 'react-router-dom';
import { ROUTE_SIGN_IN } from '../../../core';
import { isEmpty } from 'lodash';
import { GradientActionButton } from '../../../components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from '../../../core/store';

interface Props {
  handleSubmit: () => void;
  getFieldProps: (name: string) => any;
  errors?: Record<string, any>;
  loading?: boolean;
}

export const ForgotPasswordForm: FC<Props> = ({
  errors,
  handleSubmit,
  getFieldProps,
  loading
}) => {
  const classes = useStyles();
  const apiErrors = useSelector(
    (state: AppState) => state.auth.forgotPassword.errors
  );

  const handleResetPassword = () => {
    handleSubmit();
  };

  const isDisabled = !isEmpty(errors);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <Typography align="center" className={classes.title}>
          Forgot your password?
        </Typography>
        <Typography align="center" color="text.secondary" variant="body1">
          Please enter the email address associated with your account, and we'll
          email you a link to reset your password.
        </Typography>
      </Box>

      {apiErrors && <Box className={classes.apiErrors}>{apiErrors}</Box>}

      <Stack spacing={3} mb={2}>
        <TextField label="Email address" {...getFieldProps('email')} />
      </Stack>

      <GradientActionButton
        size="large"
        variant="contained"
        fullWidth
        sx={{
          mb: 2
        }}
        onClick={handleResetPassword}
        disabled={isDisabled || loading}
      >
        {loading ? <CircularProgress size={25} /> : 'Reset password'}
      </GradientActionButton>
      <Button variant="text" fullWidth component={Link} to={ROUTE_SIGN_IN}>
        Back
      </Button>
    </Box>
  );
};
