import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { Tabs } from "../../components/tabs";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { ROUTE_CLIENTS, clientStatusState } from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { Orders } from "./tabs/Orders";
import { useClient } from "../../core/hooks/useClient";
import {
  fetchActivateClient,
  fetchDeactivateClient,
} from "../../core/store/slices/clients.slice";

export const ClientDetailContainer = () => {
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState<string>("details");
  const { payload } = useClient(id);
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleDeactivate = () => {
    openConfirm({
      title: "Deactivate account",
      message: "Are really want to deactivate this account?",
      onConfirm: async () => {
        await dispatch(fetchDeactivateClient(id));
        closeConfirm();
        navigate(ROUTE_CLIENTS);
      },
    });
  };

  const handleActivate = () => {
    openConfirm({
      title: "Activate account",
      message: "Are really want to activate this account?",
      onConfirm: async () => {
        await dispatch(fetchActivateClient(id));
        closeConfirm();
        navigate(ROUTE_CLIENTS);
      },
    });
  };

  return (
    <>
      <DetailHeader
        title={payload?.fullName}
        divider={false}
        header={
          <Breadcrumbs
            items={[
              { label: "Clients", url: ROUTE_CLIENTS },
              { label: payload?.fullName },
            ]}
          />
        }
        meta={
          <StatusBadge
            label="active"
            variant={clientStatusState(payload?.isActive)}
          />
        }
      >
        {selectedTab === "details" && (
          <>
            <Button
              color="secondary"
              onClick={payload?.isActive ? handleDeactivate : handleActivate}
            >
              {payload?.isActive ? "Deactivate" : "Activate"}
            </Button>
          </>
        )}
      </DetailHeader>

      <Tabs
        hashed
        onChange={(value) => setSelectedTab(value)}
        items={[
          {
            label: "Details",
            key: "details",
            content: <Detail payload={payload} />,
          },
          {
            label: "Orders",
            key: "orders",
            content: <Orders />,
          },
        ]}
      />
    </>
  );
};
