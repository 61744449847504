import { createSlice } from "@reduxjs/toolkit";
import { SubscriberApi } from "../../http/api/subscriber.api";
import { AsyncState, createFetchAsync } from "../utils";

const subscriberApi = new SubscriberApi();

export const fetchGetAllSubscribersAsync = createFetchAsync(
  "subscriber",
  "findAll",
  async (params) => {
    try {
      const response = await subscriberApi.getAllSubscriber(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllSubscribers = fetchGetAllSubscribersAsync.asyncThunk;

export const fetchGetSubscribersCountAsync = createFetchAsync(
  "subscriber",
  "count",
  async () => {
    try {
      const response = await subscriberApi.getSubscriberCount();
      return response.count;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetSubscribersCount =
  fetchGetSubscribersCountAsync.asyncThunk;

type SubscriberState = {
  findAll: AsyncState<any>;
  count: AsyncState<any>;
};

const initialState: SubscriberState = {
  findAll: fetchGetAllSubscribersAsync.initialState,
  count: fetchGetSubscribersCountAsync.initialState,
};

export const subscribersSlice = createSlice({
  name: "subscribersSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllSubscribersAsync.extraReducers,
    ...fetchGetSubscribersCountAsync.extraReducers,
  },
});

export default subscribersSlice.reducer;
