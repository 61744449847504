import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "sign-in-form",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      backgroundColor: theme.palette.common.white,
      borderRadius: 16,
      minWidth: 488,
      padding: 32,
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: 24,
    },
    title: {
      fontSize: 24,
      lineHeight: 1.166,
      fontWeight: 600,
      marginBottom: 24,
    },
    apiErrors: {
      marginTop: 3,
      paddingLeft: 14,
      color: "#FF4842",
      fontSize: 12,
    },
    logoImgDark: {
      width: 145,
      height: 32,
      margin: "0 0 40px 0 ",
    },
  }),
  options
);

export default useStyles;
