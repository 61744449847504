import { FC } from "react";
import map from "lodash/map";
import { Box, BoxProps, Stack, Typography, Divider } from "@mui/material";
import { TypographyProps } from "@mui/material/Typography";

interface Props extends BoxProps {
  items: {
    label: string;
    value: any;
    divider?: boolean;
    shouldHideIf?: boolean;
  }[];
  spacing?: number;
  LabelProps?: TypographyProps;
  ValueProps?: TypographyProps;
}

export const LabeledList: FC<Props> = ({
  spacing = 2,
  items,
  LabelProps,
  ValueProps,
  ...props
}) => {
  return (
    <Box {...props}>
      {map(
        items,
        ({ label, value, divider, shouldHideIf }, index) =>
          !shouldHideIf && (
            <Box key={index}>
              <Stack direction="row" alignItems="center" pb={spacing}>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  width={140}
                  {...LabelProps}
                >
                  {label}
                </Typography>
                <Typography variant="body2" {...ValueProps}>
                  {value}
                </Typography>
              </Stack>
              {divider && <Divider sx={{ mb: spacing }} />}
            </Box>
          )
      )}
    </Box>
  );
};
