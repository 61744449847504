import { createSlice } from "@reduxjs/toolkit";
import { ClientApi } from "../../http/api/client.api";
import { AsyncState, createFetchAsync } from "../utils";

const clientApi = new ClientApi();

export const fetchGetAllClientsAsync = createFetchAsync(
  "client",
  "findAll",
  async (params) => {
    try {
      const response = await clientApi.getAllClients(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllClients = fetchGetAllClientsAsync.asyncThunk;

export const fetchGetClientAsync = createFetchAsync(
  "client",
  "find",
  async (params) => {
    try {
      const response = await clientApi.getClient(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetClient = fetchGetClientAsync.asyncThunk;

export const fetchCreateClientAsync = createFetchAsync(
  "client",
  "create",
  async (params) => {
    try {
      const response = await clientApi.createClient(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchCreateClient = fetchCreateClientAsync.asyncThunk;

export const fetchUpdateClientAsync = createFetchAsync(
  "client",
  "create",
  async ({ id, params }) => {
    try {
      const response = await clientApi.updateClient(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchUpdateClient = fetchUpdateClientAsync.asyncThunk;

export const fetchDeleteClientAsync = createFetchAsync(
  "client",
  "delete",
  async (id) => {
    try {
      const response = await clientApi.deleteClient(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDeleteClient = fetchDeleteClientAsync.asyncThunk;

export const fetchDeactivateClientAsync = createFetchAsync(
  "client",
  "deactivate",
  async (id) => {
    try {
      const response = await clientApi.deactivateClient(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchDeactivateClient = fetchDeactivateClientAsync.asyncThunk;

export const fetchActivateClientAsync = createFetchAsync(
  "client",
  "activate",
  async (id) => {
    try {
      const response = await clientApi.activateClient(id);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchActivateClient = fetchActivateClientAsync.asyncThunk;

export const fetchGetClientsStatsAsync = createFetchAsync(
  "client",
  "findStats",
  async (params) => {
    try {
      const response = await clientApi.getClientsStats(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetClientsStats = fetchGetClientsStatsAsync.asyncThunk;

export const fetchGetClientsCountAsync = createFetchAsync(
  "client",
  "count",
  async () => {
    try {
      const response = await clientApi.getClientsCount();
      return response.count;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetClientsCount = fetchGetClientsCountAsync.asyncThunk;

type ClientState = {
  findAll: AsyncState<any>;
  find: AsyncState<any>;
  create: AsyncState<any>;
  update: AsyncState<any>;
  delete: AsyncState<any>;
  deactivate: AsyncState<any>;
  activate: AsyncState<any>;
  findStats: AsyncState<any>;
  count: AsyncState<any>;
};

const initialState: ClientState = {
  findAll: fetchGetAllClientsAsync.initialState,
  find: fetchGetClientAsync.initialState,
  create: fetchCreateClientAsync.initialState,
  update: fetchUpdateClientAsync.initialState,
  delete: fetchDeleteClientAsync.initialState,
  deactivate: fetchDeactivateClientAsync.initialState,
  activate: fetchDeactivateClientAsync.initialState,
  findStats: fetchGetClientsStatsAsync.initialState,
  count: fetchGetClientsCountAsync.initialState,
};

export const clientSlice = createSlice({
  name: "clientsSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllClientsAsync.extraReducers,
    ...fetchGetClientAsync.extraReducers,
    ...fetchCreateClientAsync.extraReducers,
    ...fetchUpdateClientAsync.extraReducers,
    ...fetchDeleteClientAsync.extraReducers,
    ...fetchDeactivateClientAsync.extraReducers,
    ...fetchActivateClientAsync.extraReducers,
    ...fetchGetClientsStatsAsync.extraReducers,
    ...fetchGetClientsCountAsync.extraReducers,
  },
});

export default clientSlice.reducer;
