import { CircularProgress, Grid, Typography } from "@mui/material";
import { FieldSearch } from "../../../components/fields";
import { StatsCard } from "../../../components/stats-card";
import { TableView } from "../../../components/table-view";
import { Box } from "@mui/system";
import { clientOrderColumns, ROUTE_ORDERS_DETAIL, url } from "../../../core";
import { useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { useEffect, useState } from "react";
import useDebounce from "../../../core/hooks/useDebounce";
import { useListResource } from "../../../core/hooks/useListResource";
import {
  fetchGetAllOrders,
  fetchGetOrdersStats,
} from "../../../core/store/slices/orders.slice";
import { useStatsResource } from "../../../core/hooks/useStatsResource";
export const Orders = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { id: clientId } = useParams();
  const { payload: ordersPayload, loading: ordersLoading } = useStatsResource(
    {},
    "orders",
    "findStats",
    fetchGetOrdersStats
  );
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        clientId,
        embed: "waiter,restaurant",
        order: "createdAt",
      },
      "orders",
      "findAll",
      fetchGetAllOrders
    );

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item sm={4}>
          <StatsCard
            title="Orders Total"
            value={
              ordersLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {<>{formatCurrency(ordersPayload?.totalIncome)}</>}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {ordersLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>{ordersPayload?.ordersCount}</>
                  )}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Av. Bill"
            value={
              ordersLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(ordersPayload?.avgBill)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Orders (last month)"
            value={
              ordersLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {<>{formatCurrency(ordersPayload?.incomeLastMonth)}</>}
                  <Typography
                    color="text.secondary"
                    variant="h4"
                    component="span"
                  >
                    /
                  </Typography>{" "}
                  {ordersLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <>{ordersPayload?.ordersLastMonth}</>
                  )}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
      </Grid>

      <Box my={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>

      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) =>
          navigate(url(ROUTE_ORDERS_DETAIL, { orderId: id }))
        }
        dataSource={payload?.results || []}
        columns={clientOrderColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
