import { PageHeader } from "../../components/page-header";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { TableView } from "../../components/table-view";
import { Link, useNavigate } from "react-router-dom";
import {
  ROUTE_WAITERS_CREATE,
  ROUTE_WAITERS_DETAIL,
  url,
  waiterColumns,
} from "../../core";
import { FieldSearch } from "../../components/fields";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import useDebounce from "../../core/hooks/useDebounce";
import qs from "qs";
import { useListResource } from "../../core/hooks/useListResource";
import { fetchGetAllWaiters } from "../../core/store/slices/waiters.slice";
import { useCount } from "../../core/hooks/useCount";

export const WaiterListContainer = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { payload, pagination, loading, handleChangeParams, handlePageChange } =
    useListResource(
      {
        embed: "restaurants,totalIncome",
      },
      "waiters",
      "findAll",
      fetchGetAllWaiters
    );
  const { waiterCount } = useCount();

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);

    const queryString = qs.stringify(
      { embed: "restaurants,totalIncome" },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/waiters/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "waiters.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader
          title="Waiters"
          meta={
            <Typography color="text.disabled" variant="h3" component="span">
              {waiterCount}
            </Typography>
          }
        ></PageHeader>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            component={Link}
            to={ROUTE_WAITERS_CREATE}
          >
            Add new
          </Button>

          <Button
            color="secondary"
            sx={{ height: 36 }}
            onClick={() => handleExport()}
            size="small"
          >
            Export
          </Button>
        </Stack>
      </Grid>
      <Box mt={5} mb={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>
      <TableView
        showPagination
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) => navigate(url(ROUTE_WAITERS_DETAIL, { id }))}
        dataSource={payload?.results || []}
        columns={waiterColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
