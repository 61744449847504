import { LabeledList } from '../../../components/labeled-list';
import { Typography } from '@mui/material';
import { format } from 'date-fns';
import { DISPLAY_DATE_FORMAT } from '../../../core';

export const Detail = ({ payload }) => {
  return (
    <>
      <LabeledList
        mb={3}
        items={[
          {
            label: 'ID',
            value: payload?.id
          },
          {
            label: 'Name',
            value: payload?.fullName
          },
          {
            label: 'Phone',
            value: payload?.phone
          },
          {
            label: 'Created',
            value: payload?.createdAt
              ? format(new Date(payload?.createdAt), DISPLAY_DATE_FORMAT)
              : '-'
          },
          {
            label: 'Updated',
            value: payload?.updatedAt
              ? format(new Date(payload?.updatedAt), DISPLAY_DATE_FORMAT)
              : '-'
          }
        ]}
      />

      <Typography variant="h6" mb={3}>
        Banking info
      </Typography>
      <LabeledList
        mb={3}
        items={[
          {
            label: 'Sort code',
            value: payload?.sortCode
          },
          {
            label: 'Account number',
            value: payload?.accountNumber
          },
          {
            label: 'Postal code',
            value: payload?.postCode
          }
        ]}
      />
    </>
  );
};
