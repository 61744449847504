import { SvgIcon, SvgIconProps } from "@mui/material";
import { ReactComponent as SvgClients } from "./assets/clients.svg";
import { ReactComponent as SvgDashboard } from "./assets/dashboard.svg";
import { ReactComponent as SvgOrders } from "./assets/orders.svg";
import { ReactComponent as SvgRestaurants } from "./assets/restaurants.svg";
import { ReactComponent as SvgWaiters } from "./assets/waiters.svg";
import { ReactComponent as SvgVisibility } from "./assets/visibility.svg";
import { ReactComponent as SvgVisibilityOff } from "./assets/visibility-off.svg";
import { ReactComponent as SvgArrowDown } from "./assets/arrow-down.svg";
import { ReactComponent as SvgSearch } from "./assets/search.svg";
import { ReactComponent as SvgSuccess } from "./assets/success.svg";
import { ReactComponent as SvgError } from "./assets/error.svg";
import { ReactComponent as SvgSnackbarSuccess } from "./assets/snackbar-success.svg";
import { ReactComponent as SvgOmnivoreIcon } from "./assets/omnivore.svg";
import { ReactComponent as SvgTableIcon } from "./assets/table.svg";

export const IconClients = (props: SvgIconProps) => (
  <SvgIcon component={SvgClients} viewBox="0 0 22 22" {...props} />
);

export const IconDashboard = (props: SvgIconProps) => (
  <SvgIcon component={SvgDashboard} viewBox="0 0 22 22" {...props} />
);

export const IconOrders = (props: SvgIconProps) => (
  <SvgIcon component={SvgOrders} viewBox="0 0 22 22" {...props} />
);

export const IconRestaurants = (props: SvgIconProps) => (
  <SvgIcon component={SvgRestaurants} viewBox="0 0 22 22" {...props} />
);

export const IconWaiters = (props: SvgIconProps) => (
  <SvgIcon component={SvgWaiters} viewBox="0 0 22 22" {...props} />
);

export const IconOmnivore = (props: SvgIconProps) => (
  <SvgIcon component={SvgOmnivoreIcon} viewBox="0 0 76 47" {...props} />
);

export const IconTables = (props: SvgIconProps) => (
  <SvgIcon component={SvgTableIcon} viewBox="0 0 1000 1000" {...props} />
);

export const IconVisibility = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibility} viewBox="0 0 24 24" {...props} />
);

export const IconVisibilityOff = (props: SvgIconProps) => (
  <SvgIcon component={SvgVisibilityOff} viewBox="0 0 24 24" {...props} />
);

export const IconArrowDown = (props: SvgIconProps) => (
  <SvgIcon component={SvgArrowDown} viewBox="0 0 24 24" {...props} />
);

export const IconSearch = (props: SvgIconProps) => (
  <SvgIcon component={SvgSearch} viewBox="0 0 24 24" {...props} />
);

export const IconSnackbarSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSnackbarSuccess} viewBox="0 0 24 24" {...props} />
);

export const IconSuccess = (props: SvgIconProps) => (
  <SvgIcon component={SvgSuccess} viewBox="0 0 16 16" {...props} />
);

export const IconError = (props: SvgIconProps) => (
  <SvgIcon component={SvgError} viewBox="0 0 16 16" {...props} />
);
