import { StatusBadge } from "../../components/badges";
import { clientStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const clientColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, row) => (
      <StatusBadge
        label={row.isActive ? "Active" : "Inactive"}
        variant={clientStatusState(row.isActive)}
      />
    ),
  },
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Orders",
    dataIndex: "ordersCount",
    key: "ordersCount",
  },
  {
    title: "Total spent",
    dataIndex: "totalSpent",
    key: "totalSpent",
    render: (totalSpent) => formatCurrency(totalSpent),
  },
];
