import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { fetchGetRestaurant } from '../store/slices/restaurants.slice';

export const useRestaurant = (id) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.restaurants.find
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetRestaurant(id));
  }, [id, dispatch]);

  useEffect(() => {
    fetchData();
  }, [id, fetchData]);

  return { payload, errors, loading };
};
