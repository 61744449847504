import * as yup from "yup";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import {
  fetchCreateWaiter,
  fetchGetWaiter,
  fetchUpdateWaiter,
} from "../store/slices/waiters.slice";
import { fetchCounts } from "../store/slices/app.slice";

interface UseProps {
  id: string;
  onSuccess: () => void;
}

export const useWaiterForm = ({ id, onSuccess }: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      fullName: "",
      phone: "",
      notes: "",
      sortCode: "",
      accountNumber: "",
      postCode: "",
      createdAt: "",
      updatedAt: "",
      isActive: true,
    },
    validationSchema: yup.object().shape({
      fullName: yup.string().required("Required field"),
      phone: yup.string().required("Required field"),
      notes: yup.string().required("Required field").nullable(),
      sortCode: yup.string().required("Required field"),
      accountNumber: yup.string().required("Required field"),
      postCode: yup.string().required("Required field"),
    }),
    onSubmit: async (values) => {
      if (id) {
        await dispatch(fetchUpdateWaiter({ id, params: values }));
      } else {
        await dispatch(fetchCreateWaiter(values));
        dispatch(fetchCounts(false));
      }
      onSuccess();
    },
  });

  const getSingleWaiter = useCallback(async () => {
    const { payload } = await dispatch(fetchGetWaiter(id));
    setValues(payload);
  }, [dispatch, setValues, id]);

  useEffect(() => {
    if (id) {
      getSingleWaiter();
    }
  }, [id, getSingleWaiter]);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
