import { Button, Stack, TextField, Typography } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { DetailHeader } from "../../components/detail-header";
import { useNavigate, useParams } from "react-router-dom";
import {
  DISPLAY_DATE_FORMAT,
  ROUTE_WAITERS,
  waiterStatusState,
} from "../../core";
import { FieldPhone } from "../../components/fields";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { useWaiterForm } from "../../core/hooks/useWaiterForm";
import { format } from "date-fns";

export const WaiterFormContainer = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isEdit = !!id;
  const { getFieldProps, handleSubmit, values } = useWaiterForm({
    id,
    onSuccess: () => {
      navigate(ROUTE_WAITERS);
    },
  });

  return (
    <>
      <DetailHeader
        title={isEdit ? values?.fullName : "Add new waiter"}
        header={
          <Breadcrumbs
            items={[
              { label: "Waiters", url: ROUTE_WAITERS },
              { label: isEdit ? values?.fullName : "Add new waiter" },
            ]}
          />
        }
        meta={
          isEdit && (
            <StatusBadge
              label={values?.isActive ? "Active" : "Inactive"}
              variant={waiterStatusState(values?.isActive)}
            />
          )
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_WAITERS)}
        >
          Cancel
        </Button>
      </DetailHeader>
      {isEdit && (
        <Stack direction="row" spacing={3} mb={3}>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Created:
            </Typography>
            <Typography variant="body2">
              {values.createdAt
                ? format(new Date(values.createdAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Updated:
            </Typography>
            <Typography variant="body2">
              {values.updatedAt
                ? format(new Date(values.updatedAt), DISPLAY_DATE_FORMAT)
                : "-"}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack spacing={3} mb={6}>
        <TextField label="Name" {...getFieldProps("fullName")} />
        <FieldPhone label="Phone" {...getFieldProps("phone")} />
        <TextField
          label="Notes"
          multiline
          rows={3}
          {...getFieldProps("notes")}
        />
      </Stack>
      <Typography variant="h6" mb={3}>
        Banking info
      </Typography>
      <Stack spacing={3} mb={6}>
        <TextField label="Sort number" {...getFieldProps("sortCode")} />
        <TextField label="Account number" {...getFieldProps("accountNumber")} />
        <TextField label="Postal code" {...getFieldProps("postCode")} />
      </Stack>
    </>
  );
};
