import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const options = {
  name: 'gradient-button'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      background: 'linear-gradient(135deg, #235CE5 0%, #201CEA 100%)',
      '&.Mui-disabled': {
        background: 'rgba(145, 158, 171, 0.24)'
      }
    }
  }),
  options
);

export default useStyles;
