import { useState } from "react";
import { Button } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { Tabs } from "../../components/tabs";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  ROUTE_WAITERS,
  ROUTE_WAITERS_EDIT,
  url,
  waiterStatusState,
} from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Orders } from "./tabs/Orders";
import { Restaurants } from "./tabs/Restaurants";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { useWaiter } from "../../core/hooks/useWaiter";
import {
  fetchDeactivateWaiter,
  fetchActivateWaiter,
} from "../../core/store/slices/waiters.slice";
import { useDispatch } from "react-redux";

export const WaiterDetailContainer = () => {
  const { id } = useParams();
  const { payload } = useWaiter(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState<string>("details");
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const handleDeactivate = () => {
    openConfirm({
      title: "Deactivate account",
      message: "Are really want to deactivate this account?",
      onConfirm: async () => {
        await dispatch(fetchDeactivateWaiter(id));
        closeConfirm();
        navigate(ROUTE_WAITERS);
      },
    });
  };

  const handleActivate = () => {
    openConfirm({
      title: "Activate account",
      message: "Are really want to activate this account?",
      onConfirm: async () => {
        await dispatch(fetchActivateWaiter(id));
        closeConfirm();
        navigate(ROUTE_WAITERS);
      },
    });
  };

  return (
    <>
      <DetailHeader
        title={payload?.fullName}
        divider={false}
        header={
          <Breadcrumbs
            items={[
              { label: "Waiters", url: ROUTE_WAITERS },
              { label: payload?.fullName },
            ]}
          />
        }
        meta={
          <StatusBadge
            label={payload?.isActive ? "Active" : "Inactive"}
            variant={waiterStatusState(payload?.isActive)}
          />
        }
      >
        {selectedTab === "details" && (
          <>
            <Button component={Link} to={url(ROUTE_WAITERS_EDIT, { id })}>
              Edit
            </Button>
            <Button
              color="secondary"
              onClick={payload?.isActive ? handleDeactivate : handleActivate}
            >
              {payload?.isActive ? "Deactivate" : "Activate"}
            </Button>
          </>
        )}
      </DetailHeader>

      <Tabs
        hashed
        onChange={(value) => setSelectedTab(value)}
        items={[
          {
            label: "Details",
            key: "details",
            content: <Detail payload={payload} />,
          },
          {
            label: "Orders",
            key: "orders",
            content: <Orders />,
          },
          {
            label: "Restaurants",
            key: "restaurants",
            content: <Restaurants />,
          },
        ]}
      />
    </>
  );
};
