import { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { fetchLogin } from '../store/slices/auth.slice';

interface UseLoginProps {
  onSuccess: () => void;
}

export const useLogin = ({ onSuccess }: UseLoginProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched
  } = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      email: yup.string().email().required('Required field'),
      password: yup.string().required('Required field')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await dispatch(
        fetchLogin({
          email: values.email,
          password: values.password,
          onSuccess: (response) => {
            localStorage.setItem('accessToken', response.accessToken);
            setLoading(false);
            onSuccess();
          },
          onError: () => {
            setLoading(false);
          }
        })
      );
    }
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : '',
      ...formikGetFieldProps(fieldName)
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    loading
  };
};
