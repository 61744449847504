import { Grid, Stack, Box, CircularProgress, Button } from "@mui/material";
import {
  FieldDateRange,
  FieldRadioGroup,
  FieldSearch,
} from "../../../components/fields";
import { useFormik } from "formik";
import { StatsCard } from "../../../components/stats-card";
import { TableView } from "../../../components/table-view";
import {
  restaurantOrderColumns,
  ROUTE_ORDERS_DETAIL,
  url,
} from "../../../core";
import { useNavigate, useParams } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { useEffect, useMemo, useState } from "react";
import { useWaitersAutocomplete } from "../../../core/hooks/useWaitersAutocomplete";
import { endOfDay, startOfDay, sub } from "date-fns";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { FieldAutocomplete } from "../../../components/fields/field-autocomplete";
import useDebounce from "../../../core/hooks/useDebounce";
import { useListResource } from "../../../core/hooks/useListResource";
import {
  fetchGetAllOrders,
  fetchGetOrdersStats,
} from "../../../core/store/slices/orders.slice";
import { useStatsResource } from "../../../core/hooks/useStatsResource";
import qs from "qs";

export const Orders = () => {
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const [range, setRange] = useState<DateRange | undefined>(undefined);
  const { id } = useParams();
  const navigate = useNavigate();

  const { payload: waitersPayload, loading: waitersLoading } =
    useWaitersAutocomplete();
  const {
    payload: orderStatsPayload,
    loading: ordersStatsLoading,
    handleChangeParams: handleStatsChangeParams,
  } = useStatsResource(
    {
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
    },
    "orders",
    "findStats",
    fetchGetOrdersStats
  );
  const { payload, pagination, loading, handlePageChange, handleChangeParams } =
    useListResource(
      {
        restaurantId: id,
        embed: "client,waiter",
        dateFrom: startOfDay(new Date()),
        dateTo: endOfDay(new Date()),
        order: "createdAt",
      },
      "orders",
      "findAll",
      fetchGetAllOrders
    );
  const { values, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      period: "",
      dateFrom: startOfDay(new Date()),
      dateTo: endOfDay(new Date()),
      waiterId: "",
    },
    onSubmit: (values) => {
      handleChangeParams(values);
      handleStatsChangeParams(values);
    },
  });

  useEffect(() => {
    if (range) {
      setFieldValue("dateFrom", range.from || undefined);
      setFieldValue("dateTo", range.to || undefined);
      handleSubmit();
    }
  }, [range, setFieldValue, handleSubmit]);

  const waiterOptions = useMemo(
    () =>
      waitersPayload?.results
        ? waitersPayload?.results.map((item) => ({
            value: item.id,
            label: item.fullName,
          }))
        : [],
    [waitersPayload]
  );

  const handleExport = () => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    let headers = new Headers();
    const accessToken = localStorage.getItem("accessToken");
    headers.append("Authorization", `Bearer ${accessToken}`);

    const queryString = qs.stringify(
      { ...values, embed: "restaurant,waiter,client", restaurantId: id },
      { addQueryPrefix: true }
    );

    fetch(
      `${process.env.REACT_APP_API_URL}/api/v1/orders/export${queryString}`,
      {
        headers,
      }
    )
      .then((response) => response.blob())
      .then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);

        anchor.href = objectUrl;
        anchor.download = "orders.xlsx";
        anchor.click();

        window.URL.revokeObjectURL(objectUrl);
      });
  };

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        mb={3}
        spacing={2}
        height={56}
      >
        <FieldRadioGroup
          defaultValue="today"
          onChange={(value) => {
            setFieldValue("period", value);
            if (value === "today") {
              setFieldValue("dateFrom", startOfDay(new Date()));
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "last-week") {
              setFieldValue(
                "dateFrom",
                sub(startOfDay(new Date()), { days: 7 })
              );
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "last-month") {
              setFieldValue(
                "dateFrom",
                sub(startOfDay(new Date()), { months: 1 })
              );
              setFieldValue("dateTo", endOfDay(new Date()));
            } else if (value === "custom") {
              setFieldValue("dateFrom", undefined);
              setFieldValue("dateTo", undefined);
              setRange(undefined);
            }
            handleSubmit();
          }}
          items={[
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last week",
              value: "last-week",
            },
            {
              label: "Last month",
              value: "last-month",
            },
            {
              label: "Custom period",
              value: "custom",
            },
          ]}
        />
        {values.period === "custom" && (
          <FieldDateRange range={range} setRange={setRange} />
        )}

        <FieldAutocomplete
          sx={{ width: "100%" }}
          label="Waiter"
          placeholder="Select"
          loading={waitersLoading}
          disabled={waitersLoading}
          dataSource={waiterOptions}
          value={waiterOptions.find((item) => item.value === values.waiterId)}
          width="300px"
          onChange={(value: any) => {
            if (value?.value) {
              setFieldValue("waiterId", value.value);
            } else {
              setFieldValue("waiterId", "");
            }
            handleSubmit();
          }}
        />
      </Stack>

      <Grid container columnSpacing={2}>
        <Grid item sm={2.4}>
          <StatsCard
            title="Total Income"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.dateFrom || !values.dateTo
                    ? "-"
                    : formatCurrency(orderStatsPayload?.totalIncome)}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={2.4}>
          <StatsCard
            title="Total Orders"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                orderStatsPayload?.ordersCount
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={2.4}>
          <StatsCard
            title="Av. bill"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.dateFrom || !values.dateTo
                    ? "-"
                    : formatCurrency(orderStatsPayload?.avgBill)}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={2.4}>
          <StatsCard
            title="Income (last month)"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>
                  {!values.dateFrom || !values.dateTo
                    ? "-"
                    : formatCurrency(orderStatsPayload?.incomeLastMonth)}
                </>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={2.4}>
          <StatsCard
            title="Orders (last month)"
            value={
              ordersStatsLoading ? (
                <CircularProgress size={25} />
              ) : !values.dateFrom || !values.dateTo ? (
                "-"
              ) : (
                orderStatsPayload?.ordersLastMonth
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={10}>
          <Box my={2.5}>
            <FieldSearch
              size="small"
              onChange={(value) => {
                setSearchTouched(true);
                setSearch(value);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={2} justifyContent="flex-end" display="flex">
          <Button
            color="secondary"
            sx={{ height: 36 }}
            onClick={() => handleExport()}
            size="small"
          >
            Export
          </Button>
        </Grid>
      </Grid>

      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        onRowClick={({ id }) =>
          navigate(url(ROUTE_ORDERS_DETAIL, { orderId: id }))
        }
        showEmptyState
        dataSource={payload?.results || []}
        columns={restaurantOrderColumns}
        loading={loading}
      />
    </>
  );
};
