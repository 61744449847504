import qs from "qs";
import { generatePath } from "react-router";
import {
  GET_ALL_RESTAURANTS,
  GET_RESTAURANT,
  CREATE_RESTAURANT,
  UPDATE_RESTAURANT,
  DELETE_RESTAURANT,
  DEACTIVATE_RESTAURANT,
  ACTIVATE_RESTAURANT,
  GET_RESTAURANT_WAITERS,
  CONNECT_WAITER,
  DISCONNECT_WAITER,
  GET_RESTAURANTS_PAYOUTS,
  GET_PAYOUTS_STATS,
  GET_RESTAURANT_COUNT,
  ADD_RESTAURANTS_PAYOUT,
  GET_RESTAURANT_BALANCE,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class RestaurantApi extends BaseApi {
  /**
   * Get All Restaurants
   */
  public getAllRestaurants(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_RESTAURANTS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Restaurant
   */
  public getRestaurant(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_RESTAURANT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Restaurant Count
   */
  public getRestaurantCount(): Promise<any> {
    return this.httpClient
      .get(GET_RESTAURANT_COUNT)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create Restaurant
   */
  public createRestaurant(params): Promise<any> {
    return this.httpClient
      .post(CREATE_RESTAURANT, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Update Restaurant
   */
  public updateRestaurant(id, params): Promise<any> {
    return this.httpClient
      .put(generatePath(UPDATE_RESTAURANT, { id }), params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Delete Restaurant
   */
  public deleteRestaurant(id): Promise<any> {
    return this.httpClient
      .delete(generatePath(DELETE_RESTAURANT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Deactivate Restaurant
   */
  public deactivateRestaurant(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(DEACTIVATE_RESTAURANT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Activate Restaurant
   */
  public activateRestaurant(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(ACTIVATE_RESTAURANT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   Get restaurant waiters
   */
  public getRestaurantWaiters(id, params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${generatePath(GET_RESTAURANT_WAITERS, { id })}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Connect Waiter to Restaurant
   */
  public connectWaiter(restaurantId, waiterId): Promise<any> {
    return this.httpClient
      .patch(generatePath(CONNECT_WAITER, { restaurantId, waiterId }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Disconnect Waiter from Restaurant
   */
  public disconnectWaiter(restaurantId, waiterId): Promise<any> {
    return this.httpClient
      .patch(generatePath(DISCONNECT_WAITER, { restaurantId, waiterId }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   *  Restaurant Payouts
   */
  public getRestaurantPayouts(id, params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(generatePath(`${GET_RESTAURANTS_PAYOUTS}${queryString}`, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   *  Add Restaurant Payout
   */
  public addRestaurantPayout(id, values): Promise<any> {
    return this.httpClient
      .post(generatePath(ADD_RESTAURANTS_PAYOUT, { id }), values)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(response.data.message);
      });
  }

  /**
   *  Get Restaurant Balance
   */
  public getRestaurantBalance(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_RESTAURANT_BALANCE, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Orders Stats
   */
  public getPayoutsStats(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_PAYOUTS_STATS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
