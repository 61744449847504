import { FieldSearch } from "../../../components/fields";
import { TableView } from "../../../components/table-view";
import { Button, Grid, Box, CircularProgress } from "@mui/material";
import { StatsCard } from "../../../components/stats-card";
import { useDialog } from "../../../core/hooks/useDialog";
import { NewPayoutDialog } from "../dialogs/NewPayoutDialog";
import { payoutColumns } from "../../../core";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useDebounce from "../../../core/hooks/useDebounce";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { useRestaurantBalance } from "../../../core/hooks/useRestaurantBalance";
import { useListResource } from "../../../core/hooks/useListResource";
import { fetchRestaurantPayouts } from "../../../core/store/slices/restaurants.slice";

export const Payouts = () => {
  const { id } = useParams();
  const [openNewPayout] = useDialog(NewPayoutDialog);
  const [search, setSearch] = useState("");
  const [searchTouched, setSearchTouched] = useState(false);
  const debouncedValue = useDebounce(search, 500);
  const { payload, pagination, loading, handlePageChange, handleChangeParams } =
    useListResource(
      {
        restaurantId: id,
      },
      "restaurants",
      "findPayouts",
      fetchRestaurantPayouts
    );

  const { payload: balancePayload, loading: balanceLoading } =
    useRestaurantBalance(id);

  useEffect(() => {
    if (debouncedValue) {
      handleChangeParams({ search: debouncedValue });
    } else if (searchTouched) {
      handleChangeParams({ search: undefined });
    }
  }, [debouncedValue, handleChangeParams]);

  return (
    <>
      <Grid container columnSpacing={2} mb={2}>
        <Grid item sm={4}>
          <StatsCard
            title="Balance"
            value={
              balanceLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(balancePayload?.balance)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Total earned"
            value={
              balanceLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(balancePayload?.totalPayments)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
        <Grid item sm={4}>
          <StatsCard
            title="Total withdrawn"
            value={
              balanceLoading ? (
                <CircularProgress size={25} />
              ) : (
                <>{formatCurrency(balancePayload?.totalPayouts)}</>
              )
            }
            variant="grey"
            variantValue="h4"
          />
        </Grid>
      </Grid>

      <Button onClick={() => openNewPayout({ id })}>Add new payout</Button>

      <Box my={2.5}>
        <FieldSearch
          onChange={(value) => {
            setSearchTouched(true);
            setSearch(value);
          }}
        />
      </Box>

      <TableView
        pagination={pagination}
        onChangePage={handlePageChange}
        dataSource={payload?.results || []}
        columns={payoutColumns}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
