import * as yup from "yup";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import {
  fetchCreateTable,
  fetchUpdateTable,
} from "../store/slices/tables.slice";
import { useTable } from "./useTables";
import { fetchGetTable } from "../store/slices/tables.slice";
import { fetchCounts } from "../store/slices/app.slice";

interface UseProps {
  id: string;
  onSuccess: () => void;
  onError: (msg: string) => void;
}

export const useTablesForm = ({ id, onSuccess, onError }: UseProps) => {
  const dispatch = useDispatch();
  const { payload } = useTable(id);
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      restaurantId: "",
      number: "",
      name: "",
      alias: "",
      seats: "",
    },
    validationSchema: yup.object().shape({
      restaurantId: yup.number().required("Required field"),
      alias: yup.string().required("Required field"),
      name: yup.string().required("Required field"),
      number: yup.string().required("Required field").nullable(),
      seats: yup.string().required("Required field").nullable(),
    }),
    onSubmit: async (values) => {
      if (id) {
        await dispatch(
          fetchUpdateTable({ id, params: values, onSuccess, onError })
        );
      } else {
        await dispatch(fetchCreateTable({ params: values }));
        dispatch(fetchCounts(false));
      }
      onSuccess();
    },
  });

  const getSingleTable = useCallback(async () => {
    const { payload } = await dispatch(fetchGetTable(id));
    setValues(payload);
  }, [dispatch, setValues, id]);

  useEffect(() => {
    if (id) {
      getSingleTable();
    }
  }, [id, getSingleTable]);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
    payload,
  };
};
