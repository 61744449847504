export const formatCurrency = (sum?: number) => {
  if (!sum) {
    return "£0.00";
  } else {
    return new Intl.NumberFormat("en-gb", {
      style: "currency",
      currency: "GBP",
    }).format(sum / 100);
  }
};
