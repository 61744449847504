import { createSlice } from '@reduxjs/toolkit';
import { AuthApi } from '../../http/api/auth.api';
import { AsyncState, createFetchAsync } from '../utils';

const authApi = new AuthApi();

export const fetchLoginAsync = createFetchAsync(
  'auth',
  'login',
  async (params) => {
    const { email, password } = params;

    try {
      const response = await authApi.login({ email, password });
      if (params.onSuccess) {
        params.onSuccess(response);
      }
      return { success: true };
    } catch (error) {
      if (params.onError) {
        params.onError();
      }
      throw new Error(error);
    }
  }
);

export const fetchLogin = fetchLoginAsync.asyncThunk;

export const fetchForgotPasswordAsync = createFetchAsync(
  'auth',
  'me',
  async (params) => {
    const { email } = params;

    try {
      await authApi.forgotPassword({ email });
      if (params.onSuccess) {
        params.onSuccess();
      }
      return { success: true };
    } catch (error) {
      if (params.onError) {
        params.onError();
      }
      throw new Error(error);
    }
  }
);

export const fetchForgotPassword = fetchForgotPasswordAsync.asyncThunk;

export const fetchGetMeAsync = createFetchAsync('auth', 'me', async () => {
  try {
    const me = await authApi.getMe();
    return me;
  } catch (error) {
    throw new Error(error);
  }
});

export const fetchGetMe = fetchGetMeAsync.asyncThunk;

export const fetchEditMeAsync = createFetchAsync(
  'auth',
  'editMe',
  async (values) => {
    try {
      await authApi.editMe(values);
      return true;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchEditMe = fetchEditMeAsync.asyncThunk;

export const fetchChangePasswordAsync = createFetchAsync(
  'auth',
  'changePassword',
  async ({ values, onSuccess, onError }) => {
    try {
      await authApi.changePassword(values);
      if (onSuccess) {
        onSuccess();
      }
      return true;
    } catch (error) {
      if (onError) {
        onError();
      }
      throw new Error(error);
    }
  }
);

export const fetchChangePassword = fetchChangePasswordAsync.asyncThunk;

type AuthState = {
  login: AsyncState<any>;
  forgotPassword: AsyncState<any>;
  me: AsyncState<any>;
  editMe: AsyncState<any>;
  changePassword: AsyncState<any>;
};

const initialState: AuthState = {
  login: fetchLoginAsync.initialState,
  forgotPassword: fetchForgotPasswordAsync.initialState,
  me: fetchGetMeAsync.initialState,
  editMe: fetchEditMeAsync.initialState,
  changePassword: fetchChangePasswordAsync.initialState
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchLoginAsync.extraReducers,
    ...fetchForgotPasswordAsync.extraReducers,
    ...fetchGetMeAsync.extraReducers,
    ...fetchEditMeAsync.extraReducers,
    ...fetchChangePasswordAsync.extraReducers
  }
});

export default authSlice.reducer;
