import { PageHeader } from "../../components/page-header";
import { Link } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import { TableView } from "../../components/table-view";
import { ROUTE_POS_TICKET_CREATE, posTicketsColumns } from "../../core";
import { useListResource } from "../../core/hooks/useListResource";
import {
  fetchClosePosTicket,
  fetchGetAllPosTickets,
  fetchCloseAllPosTickets,
} from "../../core/store/slices/pos.slice";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { useDispatch } from "react-redux";

export const PosListContainer = () => {
  const dispatch = useDispatch();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);
  const { payload, loading } = useListResource(
    {},
    "pos",
    "findAll",
    fetchGetAllPosTickets,
    true
  );

  const handleCloseAll = () => {
    openConfirm({
      title: "Close all tickets",
      message: "Are really want to close all POS tickets?",
      onConfirm: async () => {
        await dispatch(fetchCloseAllPosTickets());
        dispatch(fetchGetAllPosTickets());
        closeConfirm();
      },
    });
  };

  const handleClosePosTicket = (id) => {
    openConfirm({
      title: "Close ticket",
      message: "Are really want to close this POS ticket?",
      onConfirm: async () => {
        await dispatch(fetchClosePosTicket({ id }));
        dispatch(fetchGetAllPosTickets());
        closeConfirm();
      },
    });
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <PageHeader title="POS opened tickets" />
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            component={Link}
            to={ROUTE_POS_TICKET_CREATE}
          >
            Add new
          </Button>
          <Button
            color="secondary"
            sx={{ height: 36 }}
            onClick={() => handleCloseAll()}
            size="small"
          >
            Close all tickets
          </Button>
        </Stack>
      </Grid>
      <TableView
        dataSource={payload || []}
        columns={posTicketsColumns(handleClosePosTicket)}
        loading={loading}
        showEmptyState
      />
    </>
  );
};
