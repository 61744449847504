import { StatusBadge } from "../../components/badges";
import { waiterStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const waiterColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (_, row) => (
      <StatusBadge
        label={row.isActive ? "Active" : "Inactive"}
        variant={waiterStatusState(row.isActive)}
      />
    ),
  },
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Phone number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Connected Restaurant",
    dataIndex: "restaurants",
    key: "restaurants",
    render: (restaurants) =>
      restaurants &&
      restaurants.map((restaurant) => {
        return <div>{restaurant?.restaurant?.name}</div>;
      }),
  },
  {
    title: "Total Income",
    dataIndex: "totalIncome",
    key: "totalIncome",
    render: (totalIncome) => formatCurrency(totalIncome),
  },
];
