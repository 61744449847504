import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";

export const useStatsResource = (
  filters = {},
  reducerName,
  reducerObj,
  dispatchFn
) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state[reducerName][reducerObj]
  );
  const [params, setParams] = useState<any>({
    ...filters,
  });

  const handleChangeParams = useCallback(
    (filters) => {
      setParams((p) => ({
        ...p,
        ...filters,
      }));
    },
    [setParams]
  );

  const fetchData = useCallback(() => {
    dispatch(dispatchFn(params));
  }, [params, dispatch, dispatchFn]);

  useEffect(() => {
    fetchData();
  }, [params, fetchData]);

  return {
    payload,
    errors,
    loading,
    handleChangeParams,
  };
};
