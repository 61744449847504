import { FC } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AppState } from "../../../core/store";
import useStyles from "./styles";
import { PasswordField } from "../../../components/fields";
import { isEmpty } from "lodash";
import alacartLogoDark from "../../../../src/images/alacartLogo-dark.png";

interface Props {
  handleSubmit: () => void;
  getFieldProps: (name: string) => any;
  errors?: Record<string, any>;
  loading?: boolean;
}

export const SignInForm: FC<Props> = ({
  errors,
  handleSubmit,
  getFieldProps,
  loading,
}) => {
  const classes = useStyles();
  const apiErrors = useSelector((state: AppState) => state.auth.login.errors);

  const handleLogin = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const isDisabled = !isEmpty(errors);

  return (
    <Box className={classes.root}>
      <Box className={classes.header}>
        <img
          className={classes.logoImgDark}
          src={alacartLogoDark}
          alt="Alacart Logo"
        />
        <Typography align="center" className={classes.title}>
          Admin Panel
        </Typography>
        <Typography color="text.secondary" variant="body1">
          Sign in
        </Typography>
      </Box>

      <form onSubmit={handleLogin}>
        <Stack spacing={3} mb={2}>
          <TextField label="User name" {...getFieldProps("email")} />
          <div>
            <PasswordField label="Password" {...getFieldProps("password")} />
            {apiErrors && <Box className={classes.apiErrors}>{apiErrors}</Box>}
          </div>
        </Stack>

        <Button
          size="large"
          variant="contained"
          fullWidth
          disabled={isDisabled || loading}
          type="submit"
          sx={{ mb: 2 }}
        >
          {loading ? <CircularProgress size={25} /> : "Login"}
        </Button>
      </form>
    </Box>
  );
};
