import * as yup from "yup";
import { useFormik } from "formik";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import { fetchCreatePosTicket } from "../store/slices/pos.slice";

interface UseProps {
  id: string;
  onSuccess: () => void;
}

export const usePosForm = ({ id, onSuccess }: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      employee: "",
      table: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Required field"),
      employee: yup.string().required("Required field"),
      table: yup.string().required("Required field"),
    }),
    onSubmit: async (values) => {
      if (id) {
        console.log("Not implemented");
      } else {
        await dispatch(fetchCreatePosTicket(values));
      }
      onSuccess();
    },
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
  };
};
