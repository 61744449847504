import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import bg from '../../../images/bg.png';

const options = {
  name: 'auth-layout'
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      position: 'relative',
      minHeight: '100vh',
      background: `url(${bg}) no-repeat center ${theme.palette.primary.main}`,
      backgroundPosition: 'right',
      backgroundSize: '98% auto'
    },
    container: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      padding: theme.spacing(0, 2)
    }
  }),
  options
);

export default useStyles;
