import * as yup from "yup";
import { useFormik } from "formik";
import { useCallback, useEffect } from "react";
import get from "lodash/get";
import { useDispatch } from "react-redux";
import {
  fetchCreateRestaurant,
  fetchGetRestaurant,
  fetchUpdateRestaurant,
} from "../store/slices/restaurants.slice";
import { fetchCounts } from "../store/slices/app.slice";

interface UseProps {
  id: string;
  onSuccess: () => void;
}

export const useRestaurantForm = ({ id, onSuccess }: UseProps) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    setValues,
  } = useFormik({
    initialValues: {
      omnivoreLocationId: "",
      name: "",
      phone: "",
      email: "",
      address: "",
      wifiSsid: "",
      wifiPassword: "",
      notes: "",
      sortCode: "",
      accountNumber: "",
      postCode: "",
      createdAt: "",
      updatedAt: "",
      isActive: true,
      comtrexUrl: "",
      comtrexLogin: "",
      comtrexPassword: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Required field"),
      phone: yup.string().required("Required field"),
      email: yup
        .string()
        .email("Please enter valid email")
        .required("Required field"),
      address: yup.string().required("Required field"),
      wifiSsid: yup.string(),
      wifiPassword: yup.string(),
      notes: yup.string().nullable(),
      sortCode: yup.string().required("Required field"),
      accountNumber: yup.string().required("Required field"),
      postCode: yup.string().required("Required field"),
      omnivoreLocationId: yup
        .string()
        .nullable()
        .test(
          "requiredIfComtrex",
          "Field is required when Comtrex fields are not filled",
          function (value) {
            if (
              this.parent.comtrexUrl ||
              this.parent.comtrexLogin ||
              this.parent.comtrexPassword
            ) {
              return true;
            }
            return !!value;
          }
        ),
      comtrexUrl: yup
        .string()
        .nullable()
        .test(
          "requiredIfComtrex",
          "Field is required when Omnivore ID is not filled",
          function (value) {
            if (this.parent.omnivoreLocationId) {
              return true;
            }
            return !!value;
          }
        ),
      comtrexLogin: yup
        .string()
        .nullable()
        .test(
          "requiredIfComtrex",
          "Field is required when Omnivore ID is not filled",
          function (value) {
            if (this.parent.omnivoreLocationId) {
              return true;
            }
            return !!value;
          }
        ),
      comtrexPassword: yup
        .string()
        .trim()
        .nullable()
        .test(
          "requiredIfComtrex",
          "Field is required when Omnivore ID is not filled",
          function (value) {
            if (this.parent.omnivoreLocationId) {
              return true;
            }
            return !!value;
          }
        ),
    }),
    onSubmit: async (values) => {
      const comtrex: { username?: string; password?: string; url?: string } =
        {};

      if (values.comtrexLogin) {
        comtrex.username = values.comtrexLogin;
      }
      if (values.comtrexPassword) {
        comtrex.password = values.comtrexPassword;
      }
      if (values.comtrexUrl) {
        comtrex.url = values.comtrexUrl;
      }

      const formattedValues = { ...values, comtrex };

      if (id) {
        await dispatch(fetchUpdateRestaurant({ id, params: formattedValues }));
      } else {
        await dispatch(fetchCreateRestaurant(values));
        dispatch(fetchCounts(false));
      }
      onSuccess();
    },
  });

  const getSingleRestaurant = useCallback(async () => {
    const { payload } = await dispatch(fetchGetRestaurant(id));
    setValues({
      ...payload,
      comtrexLogin: payload?.comtrex?.username || "",
      comtrexPassword: payload?.comtrex?.password || "",
      comtrexUrl: payload?.comtrex?.url || "",
    });
  }, [setValues, dispatch, id]);

  useEffect(() => {
    if (id) {
      getSingleRestaurant();
    }
  }, [id, getSingleRestaurant]);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : "",
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, "target.value");
        if (targetValue || targetValue === "") {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      },
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    setFieldValue,
  };
};
