import {
  AUTH_LOGIN,
  AUTH_FORGOT_PASSWORD,
  AUTH_GET_ME,
  AUTH_EDIT_ME,
  AUTH_CHANGE_PASSWORD
} from '../api.routes';
import { handleError } from '../utils';
import { BaseApi } from './base.api';

export class AuthApi extends BaseApi {
  /**
   * Login
   * @param email
   * @param password
   */
  public login(data): Promise<any> {
    return this.httpClient
      .post(AUTH_LOGIN, data)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Forgot password
   * @param email
   */
  public forgotPassword(data): Promise<any> {
    return this.httpClient
      .post(AUTH_FORGOT_PASSWORD, data)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Me
   */
  public getMe(): Promise<any> {
    return this.httpClient
      .get(AUTH_GET_ME)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Edit me
   * @param fullName
   * @param email
   */
  public editMe(values): Promise<any> {
    return this.httpClient
      .put(AUTH_EDIT_ME, values)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Change Password
   * @param password
   * @param newPassword
   */
  public changePassword(values): Promise<any> {
    return this.httpClient
      .put(AUTH_CHANGE_PASSWORD, values)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
