import { TextField as MuiTextField, TextFieldProps } from "@mui/material";
import { PatternFormat } from "react-number-format";
import { FC } from "react";

interface Props extends Omit<TextFieldProps, "error" | "variant" | "errors"> {
  errors?: any;
  value?: any;
  onChange?: (e: any) => void;
  onBlur?: (e: any) => void;
  required?: boolean;
  maxLength?: number;
}

export const FieldPhone: FC<Props> = ({ value, onChange, ...props }) => {
  const handleChange = ({ value }) => {
    onChange?.(value);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PatternFormat
      {...props}
      value={value}
      customInput={MuiTextField}
      valueIsNumericString={true}
      format="+## ### #### ####"
      onValueChange={handleChange}
    />
  );
};
