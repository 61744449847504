import { format } from "date-fns";
import { get } from "lodash";
import { DISPLAY_DATE_FORMAT } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

const transactionTypes = {
  credit: "Credit",
  refund: "Refund",
  charge: "Charge",
};

export const orderTransactionsColumns = [
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (type) => transactionTypes[type],
  },
  {
    title: "Added by",
    dataIndex: "addedBy",
    key: "addedBy",
    render: (addedBy) => get(addedBy, "fullName", "-"),
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (v) => format(new Date(v), DISPLAY_DATE_FORMAT),
  },
  {
    title: "Total",
    dataIndex: "amount",
    key: "amount",
    render: (amount) => formatCurrency(+amount),
  },
];
