import { Button, ButtonProps } from '@mui/material';
import { FC } from 'react';
import useStyles from './styles';

export const GradientActionButton: FC<ButtonProps> = ({
  children,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Button className={classes.root} {...props}>
      {children}
    </Button>
  );
};
