import { format } from "date-fns";
import { StatusBadge } from "../../components/badges";
import { DISPLAY_DATE_FORMAT, orderStatusState } from "../constants";
import { formatCurrency } from "../utils/currency-format.utils";

export const incomeColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Date/time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (v) => format(new Date(v), DISPLAY_DATE_FORMAT),
  },
  {
    title: "Status",
    dataIndex: "isPaid",
    key: "isPaid",
    render: (isPaid) => (
      <StatusBadge
        label={isPaid ? "Paid" : "Unpaid"}
        variant={orderStatusState(isPaid)}
      />
    ),
  },
  {
    title: "Client",
    dataIndex: "client.fullName",
    key: "client.fullName",
  },
  {
    title: "Restaurant",
    dataIndex: "restaurant.name",
    key: "restaurant.name",
  },
  {
    title: "Income",
    dataIndex: "totalFinal",
    key: "totalFinal",
    render: (totalFinal) => formatCurrency(totalFinal),
  },
];
