import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const options = {
  name: "base-layout",
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    root: {
      height: "100vh",
      display: "flex",
      // backgroundColor: "#F4F6F8",
      backgroundColor: "#fff",
    },
    main: {
      padding: theme.spacing(2, 3, 4, 3),
      flexGrow: 1,
    },
    header: {},
  }),
  options
);

export default useStyles;
