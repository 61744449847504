import { useNavigate } from 'react-router-dom';
import { ForgotPasswordForm } from './forgot-password-form/ForgotPasswordForm';
import { ROUTE_DASHBOARD } from '../../core';
import { useForgotPassword } from '../../core/hooks/useForgotPassword';

export const ForgotPasswordContainer = () => {
  const navigate = useNavigate();

  const { handleSubmit, getFieldProps, errors, loading } = useForgotPassword({
    onSuccess: () => {
      navigate(ROUTE_DASHBOARD);
    }
  });

  return (
    <ForgotPasswordForm {...{ handleSubmit, getFieldProps, errors, loading }} />
  );
};
