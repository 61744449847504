import { FC, ReactNode } from "react";
import useStyles from "./styles";
import { Box, BoxProps, Typography } from "@mui/material";
import clsx from "clsx";
import { TypographyProps } from "@mui/material/Typography";

interface Props extends BoxProps {
  title: string;
  value: ReactNode;
  variant?: "white" | "grey";
  variantValue?: TypographyProps["variant"];
  variantTitle?: TypographyProps["variant"];
}
export const StatsCard: FC<Props> = ({
  title,
  value,
  variant = "white",
  variantValue = "h3",
  variantTitle = "subtitle2",
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.root, {
        [classes.white]: variant === "white",
        [classes.grey]: variant === "grey",
      })}
      {...props}
    >
      <Typography variant={variantTitle}>{title}</Typography>
      <Typography variant={variantValue}>{value}</Typography>
    </Box>
  );
};
