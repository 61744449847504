import { useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { fetchForgotPassword } from '../store/slices/auth.slice';

interface UseForgotPasswordProps {
  onSuccess: () => void;
}

export const useForgotPassword = ({ onSuccess }: UseForgotPasswordProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    errors,
    values,
    touched
  } = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .email('Please enter valid email')
        .required('Required field')
    }),
    onSubmit: async (values) => {
      setLoading(true);
      await dispatch(
        fetchForgotPassword({
          email: values.email,
          onSuccess: () => {
            setLoading(false);
            onSuccess();
          },
          onError: () => {
            // TODO: REMOVE ME
            setLoading(false);
          }
        })
      );
    }
  });

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : '',
      ...formikGetFieldProps(fieldName)
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    loading
  };
};
