import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";
import { fetchCounts } from "../store/slices/app.slice";
import { fetchGetClientsCount } from "../store/slices/clients.slice";
import { fetchGetOrdersCount } from "../store/slices/orders.slice";
import { fetchGetRestaurantsCount } from "../store/slices/restaurants.slice";
import { fetchGetSubscribersCount } from "../store/slices/subscribers.slice";
import { fetchGetWaiterCount } from "../store/slices/waiters.slice";

export const useCount = () => {
  const dispatch = useDispatch();

  const { payload: restaurantsCountPayload } = useSelector(
    (state: AppState) => state.restaurants.count
  );

  const { payload: clientsCountPayload } = useSelector(
    (state: AppState) => state.clients.count
  );

  const { payload: waitersCountPayload } = useSelector(
    (state: AppState) => state.waiters.count
  );

  const { payload: ordersCountPayload } = useSelector(
    (state: AppState) => state.orders.count
  );
  const { payload: subscribersCountPayload } = useSelector(
    (state: AppState) => state.subscribers.count
  );
  const countsFetched = useSelector(
    (state: AppState) => state.app.countsFetched
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetRestaurantsCount());
    dispatch(fetchGetSubscribersCount());
    dispatch(fetchGetWaiterCount());
    dispatch(fetchGetClientsCount());
    dispatch(fetchGetOrdersCount());
    dispatch(fetchCounts(true));
  }, [dispatch]);

  useEffect(() => {
    if (!countsFetched) {
      fetchData();
    }
  }, [fetchData]);

  return {
    restaurantCount: restaurantsCountPayload,
    waiterCount: waitersCountPayload,
    clientCount: clientsCountPayload,
    orderCount: ordersCountPayload,
    subscriberCount: subscribersCountPayload,
  };
};
