export const AUTH_LOGIN = "/api/v1/auth/users/login";
export const AUTH_FORGOT_PASSWORD = "/api/v1/auth/restaurants/new-pin-code";
export const AUTH_GET_ME = "api/v1/users/me";
export const AUTH_EDIT_ME = "api/v1/users/me";
export const AUTH_CHANGE_PASSWORD = "api/v1/users/change-password";

export const GET_ALL_RESTAURANTS = "/api/v1/restaurants";
export const GET_RESTAURANT = "/api/v1/restaurants/:id";
export const CREATE_RESTAURANT = "/api/v1/restaurants";
export const UPDATE_RESTAURANT = "/api/v1/restaurants/:id";
export const DELETE_RESTAURANT = "/api/v1/restaurants/:id";
export const DEACTIVATE_RESTAURANT = "/api/v1/restaurants/:id/deactivate";
export const ACTIVATE_RESTAURANT = "/api/v1/restaurants/:id/activate";
export const GET_RESTAURANT_WAITERS = "/api/v1/restaurants/:id/waiters";
export const CONNECT_WAITER =
  "/api/v1/restaurants/:restaurantId/waiters/:waiterId/activate";
export const DISCONNECT_WAITER =
  "/api/v1/restaurants/:restaurantId/waiters/:waiterId/deactivate";
export const GET_RESTAURANTS_PAYOUTS = "api/v1/restaurants/:id/payouts";
export const ADD_RESTAURANTS_PAYOUT = "api/v1/restaurants/:id/payouts";
export const GET_PAYOUTS_STATS = "/api/v1/restaurants/payouts-stats";
export const GET_RESTAURANT_COUNT = "api/v1/restaurants/count";
export const GET_RESTAURANT_BALANCE = "/api/v1/restaurants/:id/balance";
export const GET_ALL_WAITERS = "/api/v1/waiters";
export const GET_WAITER = "/api/v1/waiters/:id";
export const GET_WAITER_COUNT = "api/v1/waiters/count";
export const CREATE_WAITER = "/api/v1/waiters";
export const UPDATE_WAITER = "/api/v1/waiters/:id";
export const DEACTIVATE_WAITER = "/api/v1/waiters/:id/deactivate";
export const ACTIVATE_WAITER = "/api/v1/waiters/:id/activate";

export const GET_ALL_CLIENTS = "/api/v1/clients";
export const GET_CLIENT = "/api/v1/clients/:id";
export const GET_CLIENT_COUNT = "api/v1/clients/count";
export const CREATE_CLIENT = "/api/v1/clients";
export const UPDATE_CLIENT = "/api/v1/clients/:id";
export const DELETE_CLIENT = "/api/v1/clients/:id";
export const DEACTIVATE_CLIENT = "/api/v1/clients/:id/deactivate";
export const ACTIVATE_CLIENT = "/api/v1/clients/:id/activate";
export const GET_CLIENTS_STATS = "/api/v1/clients/stats";

export const GET_ALL_ORDERS = "/api/v1/orders";
export const GET_ORDER = "/api/v1/orders/:id";
export const GET_ORDER_COUNT = "api/v1/orders/count";
export const CREATE_ORDER = "/api/v1/orders";
export const UPDATE_ORDER = "/api/v1/orders/:id";
export const DELETE_ORDER = "/api/v1/orders/:id";
export const GET_ORDER_ITEMS = "/api/v1/orders/:id/items";
export const REQUEST_FINAL_BILL = "/api/v1/orders/:id/final";
export const CLOSE_ORDER = "/api/v1/orders/:id/close";
export const CLOSE_ALL_ORDERS = "/api/v1/orders/close-all/:restaurantId";
export const GET_ORDERS_STATS = "/api/v1/orders/stats";
export const ORDER_CREATE_TRANSACTION = "/api/v1/orders/:id/transactions";
export const GET_TRANSACTION_STATS = "/api/v1/payments/stats";
export const GET_TRANSACTIONS = "/api/v1/payments";

export const GET_ALL_SUBSCRIBERS = "/api/v1/subscribers";
export const GET_SUBSCRIBERS_COUNT = "/api/v1/subscribers/count";

export const GET_ALL_POS_TICKETS = "api/v1/omnivore/tickets";
export const CREATE_POS_TICKET = "api/v1/omnivore/tickets";
export const CLOSE_ALL_POS_TICKETS = "api/v1/omnivore/tickets/close-all";
export const ADD_ITEMS_TO_POS_TICKET = "api/v1/omnivore/tickets/:id/items";
export const CLOSE_POS_TICKET = "api/v1/omnivore/tickets/:id/pay";

export const GET_ALL_TABLES = "api/v1/tables";
export const GET_TABLE = "/api/v1/tables/:id";
export const UPDATE_TABLE = "/api/v1/tables/:id";
export const CREATE_TABLE = "/api/v1/tables";
