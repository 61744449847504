import { format } from "date-fns";
import { DISPLAY_DATE_FORMAT } from "../constants";

export const subscriberColumns = [
  {
    title: "#",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Date/time",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (v) => format(new Date(v), DISPLAY_DATE_FORMAT),
  },
];
