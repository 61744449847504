import { createSlice } from "@reduxjs/toolkit";
import { TableApi } from "../../http/api/table.api";
import { AsyncState, createFetchAsync } from "../utils";

const tableApi = new TableApi();

export const fetchGetAllTablesAsync = createFetchAsync(
  "tables",
  "findAll",
  async (params) => {
    try {
      const response = await tableApi.getAllTables(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetAllTables = fetchGetAllTablesAsync.asyncThunk;

export const fetchGetTableAsync = createFetchAsync(
  "tables",
  "find",
  async (params) => {
    try {
      const response = await tableApi.getTable(params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

export const fetchGetTable = fetchGetTableAsync.asyncThunk;

export const fetchUpdateTableAsync = createFetchAsync(
  "tables",
  "edit",
  async ({ id, params, onSuccess, onError }) => {
    try {
      const response = await tableApi.updateTable(id, params);
      onSuccess();
      return response.data;
    } catch (error) {
      onError(error.message);
      throw new Error(error);
    }
  }
);

export const fetchUpdateTable = fetchUpdateTableAsync.asyncThunk;

export const fetchCreateTableAsync = createFetchAsync(
  "tables",
  "edit",
  async ({ params, onSuccess, onError }) => {
    try {
      const response = await tableApi.createTable(params);
      onSuccess();
      return response.data;
    } catch (error) {
      onError(error.message);
      throw new Error(error);
    }
  }
);

export const fetchCreateTable = fetchCreateTableAsync.asyncThunk;

export const fetchUpdateRestaurantAsync = createFetchAsync(
  "restaurant",
  "create",
  async ({ id, params }) => {
    try {
      const response = await tableApi.updateTable(id, params);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  }
);

type TablesState = {
  findAll: AsyncState<any>;
  find: AsyncState<any>;
  edit: AsyncState<any>;
  create: AsyncState<any>;
};

const initialState: TablesState = {
  findAll: fetchGetAllTablesAsync.initialState,
  find: fetchGetTableAsync.initialState,
  edit: fetchUpdateTableAsync.initialState,
  create: fetchCreateTableAsync.initialState,
};

export const tablesSlice = createSlice({
  name: "tablesSlice",
  initialState,
  reducers: {},
  extraReducers: {
    ...fetchGetAllTablesAsync.extraReducers,
    ...fetchGetTableAsync.extraReducers,
    ...fetchUpdateTableAsync.extraReducers,
    ...fetchCreateTableAsync.extraReducers,
  },
});

export default tablesSlice.reducer;
