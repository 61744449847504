import { ComponentType, ReactNode } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Stack, TextField } from "@mui/material";
import { DialogProps } from "../../../core/providers/dialog.provider";
import { FieldSelect } from "../../../components/fields";
import { useMakeTransactionForm } from "../../../core/hooks/useMakeTransactionForm";
import { formatCurrency } from "../../../core/utils/currency-format.utils";
import { useSnackbar } from "../../../core/hooks/useSnackbar";

interface Props extends DialogProps {
  id: string;
  total?: number;
  fetchData?: () => void;
  title?: string;
  message?: string;
  onConfirm: () => void;
  okText?: string;
  cancelText?: string;
  body?: ReactNode;
}

export const MakeTransactionDialog: ComponentType<Props> = ({
  id,
  total,
  fetchData,
  popDialog,
}) => {
  const { showMessage } = useSnackbar();
  const { getFieldProps, handleSubmit } = useMakeTransactionForm({
    id,
    onSuccess: () => {
      fetchData();
      popDialog();
    },
    onError: (error) => {
      showMessage(error, "error");
    },
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ "&>.MuiDialog-container>.MuiPaper-root": { minWidth: 440 } }}
    >
      <DialogTitle>Make a transaction</DialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={1}>
          <FieldSelect
            label="Transaction type"
            items={[
              {
                label: "Refund",
                value: "refund",
              },
              {
                label: "Charge",
                value: "charge",
              },
              {
                label: "Credit",
                value: "credit",
              },
            ]}
            {...getFieldProps("type")}
          />
          <TextField
            label="Amount"
            type="number"
            {...getFieldProps("amount")}
            InputProps={{ inputProps: { min: 0 } }}
            helperText={`Total: ${formatCurrency(total)}`}
          />
          <TextField
            label="Comment"
            multiline
            rows={4}
            {...getFieldProps("comment")}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={popDialog}>
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
};
