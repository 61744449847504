import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../store";

const DEFAULT_LIMIT = 10;

interface Params {
  limit: number;
  page: number;
  order?: string;
}

export const useListResource = (
  filters = {},
  reducerName,
  reducerObj,
  dispatchFn,
  noPagination = false
) => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state[reducerName][reducerObj]
  );

  const [params, setParams] = useState<Params>({
    limit: DEFAULT_LIMIT,
    page: 1,
    ...filters,
  });

  const pagination = {
    total: payload?.total || 0,
    page: params.page,
    totalPages: (payload?.total || 0) / params.page,
    perPage: 10,
  };

  const handlePageChange = useCallback(
    (page) => {
      setParams((prev) => ({ ...prev, page: page + 1 }));
    },
    [setParams]
  );

  const fetchData = useCallback(() => {
    dispatch(dispatchFn(noPagination ? {} : params));
  }, [params, dispatch, dispatchFn, noPagination]);

  useEffect(() => {
    fetchData();
  }, [params, fetchData]);

  const handleChangeParams = useCallback(
    (filters) => {
      setParams((p) => ({
        ...p,
        limit: DEFAULT_LIMIT,
        page: 1,
        ...filters,
      }));
    },
    [setParams]
  );

  return {
    payload,
    errors,
    loading,
    pagination,
    handleChangeParams,
    handlePageChange,
  };
};
