import { Button, Stack, TextField, Typography } from '@mui/material';
import { DetailHeader } from '../../components/detail-header';
import { useNavigate } from 'react-router-dom';
import { DISPLAY_DATE_FORMAT, ROUTE_PROFILE } from '../../core';
import { Breadcrumbs } from '../../components/breadcrumbs';
import { useProfileForm } from '../../core/hooks/useProfileForm';
import { format } from 'date-fns';

export const ProfileFormContainer = () => {
  const navigate = useNavigate();
  const { payload, getFieldProps, handleSubmit } = useProfileForm({
    onSuccess: () => {
      navigate(ROUTE_PROFILE);
    }
  });

  return (
    <>
      <DetailHeader
        title="Edit profile"
        header={
          <Breadcrumbs
            items={[
              { label: 'My profile', url: ROUTE_PROFILE },
              {
                label: 'Edit profile'
              }
            ]}
          />
        }
      >
        <Button onClick={() => handleSubmit()}>Save</Button>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(ROUTE_PROFILE)}
        >
          Cancel
        </Button>
      </DetailHeader>
      {payload && (
        <Stack direction="row" spacing={3} mb={3}>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Created:
            </Typography>
            <Typography variant="body2">
              {format(new Date(payload?.createdAt), DISPLAY_DATE_FORMAT)}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <Typography variant="body2" color="text.secondary">
              Updated:
            </Typography>
            <Typography variant="body2">
              {format(new Date(payload?.updatedAt), DISPLAY_DATE_FORMAT)}
            </Typography>
          </Stack>
        </Stack>
      )}

      <Stack spacing={3}>
        <TextField label="Name" {...getFieldProps('fullName')} />
        <TextField label="E-mail" {...getFieldProps('email')} />
      </Stack>
    </>
  );
};
