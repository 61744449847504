import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../store';
import { fetchGetMe } from '../store/slices/auth.slice';

export const useUser = () => {
  const dispatch = useDispatch();
  const { payload, errors, loading } = useSelector(
    (state: AppState) => state.auth.me
  );

  const fetchData = useCallback(() => {
    dispatch(fetchGetMe());
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { payload, errors, loading };
};
