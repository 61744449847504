import React, { ComponentType } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DialogProps } from '../../../core/providers/dialog.provider';
import { PasswordField } from '../../../components/fields';
import { useSnackbar } from '../../../core/hooks/useSnackbar';
import { useChangePassword } from '../../../core/hooks/useChangePassword';

interface Props extends DialogProps {}

export const ChangePasswordDialog: ComponentType<Props> = ({ popDialog }) => {
  const { showMessage } = useSnackbar();
  const { handleSubmit, getFieldProps } = useChangePassword({
    onSuccess: () => {
      showMessage('Password successfully changed');
      popDialog();
    },
    onError: () => {
      showMessage('Password change failed', 'error');
    }
  });

  return (
    <Dialog
      open
      onClose={popDialog}
      sx={{ '&>.MuiDialog-container>.MuiPaper-root': { minWidth: 440 } }}
    >
      <DialogTitle>Change password</DialogTitle>
      <DialogContent>
        <Stack spacing={3} pt={1}>
          <PasswordField
            label="Current password"
            {...getFieldProps('password')}
          />
          <PasswordField
            label="New password"
            {...getFieldProps('newPassword')}
          />
          <PasswordField
            label="Confirm new password"
            {...getFieldProps('passwordConfirmation')}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" variant="outlined" onClick={popDialog}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmit();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
