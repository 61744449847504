import * as yup from 'yup';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import get from 'lodash/get';
import { useUser } from './useUser';
import { useDispatch } from 'react-redux';
import { fetchEditMe } from '../store/slices/auth.slice';

interface UseProps {
  onSuccess: () => void;
}

export const useProfileForm = ({ onSuccess }: UseProps) => {
  const { payload } = useUser();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    getFieldProps: formikGetFieldProps,
    setFieldValue,
    errors,
    values,
    touched,
    setValues
  } = useFormik({
    initialValues: {
      fullName: payload?.fullName,
      email: payload?.email
    },
    validationSchema: yup.object().shape({
      fullName: yup.string().required('Required field'),
      email: yup
        .string()
        .email('Please enter valid email')
        .required('Required field')
    }),
    onSubmit: async (values) => {
      await dispatch(fetchEditMe(values));
      onSuccess();
    }
  });

  useEffect(() => {
    setValues({
      fullName: payload?.fullName,
      email: payload?.email
    });
  }, [setValues, payload]);

  const getFieldProps = (fieldName) => {
    const error = errors[fieldName] && touched[fieldName];
    return {
      error,
      helperText: error ? errors[fieldName] : '',
      ...formikGetFieldProps(fieldName),
      onChange: (e) => {
        const targetValue = get(e, 'target.value');
        if (targetValue || targetValue === '') {
          setFieldValue(fieldName, targetValue);
        } else {
          setFieldValue(fieldName, e);
        }
      }
    };
  };

  return {
    handleSubmit,
    errors,
    values,
    getFieldProps,
    payload
  };
};
