import qs from "qs";
import { generatePath } from "react-router-dom";
import {
  CREATE_CLIENT,
  DELETE_CLIENT,
  GET_ALL_CLIENTS,
  GET_CLIENT,
  UPDATE_CLIENT,
  DEACTIVATE_CLIENT,
  GET_CLIENTS_STATS,
  GET_CLIENT_COUNT,
  ACTIVATE_CLIENT,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class ClientApi extends BaseApi {
  /**
   * Get All Clients
   */
  public getAllClients(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_CLIENTS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Client
   */
  public getClient(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_CLIENT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Create Client
   */
  public createClient(params): Promise<any> {
    return this.httpClient
      .post(CREATE_CLIENT, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Update Client
   */
  public updateClient(id, params): Promise<any> {
    return this.httpClient
      .put(generatePath(UPDATE_CLIENT, { id }), params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Delete Client
   */
  public deleteClient(id): Promise<any> {
    return this.httpClient
      .delete(generatePath(DELETE_CLIENT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Deactivate Client
   */
  public deactivateClient(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(DEACTIVATE_CLIENT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Activate Client
   */
  public activateClient(id): Promise<any> {
    return this.httpClient
      .patch(generatePath(ACTIVATE_CLIENT, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Clients Stats
   */
  public getClientsStats(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_CLIENTS_STATS}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }

  /**
   * Get Clients Count
   */
  public getClientsCount(): Promise<any> {
    return this.httpClient
      .get(GET_CLIENT_COUNT)
      .then((response) => {
        return response.data;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
