import { useState } from "react";
import { Button } from "@mui/material";
import { StatusBadge } from "../../components/badges";
import { Tabs } from "../../components/tabs";
import { DetailHeader } from "../../components/detail-header";
import { Detail } from "./tabs/Detail";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  restaurantStatusState,
  ROUTE_RESTAURANTS,
  ROUTE_RESTAURANTS_EDIT,
  url,
} from "../../core";
import { useDialog } from "../../core/hooks/useDialog";
import { ConfirmDialog } from "../../components/dialogs";
import { Orders } from "./tabs/Orders";
import { Waiters } from "./tabs/Waiters";
import { Payouts } from "./tabs/Payouts";
import { Breadcrumbs } from "../../components/breadcrumbs";
import { useRestaurant } from "../../core/hooks/useRestaurant";
import { useDispatch } from "react-redux";
import {
  fetchDeactivateRestaurant,
  fetchActivateRestaurant,
} from "../../core/store/slices/restaurants.slice";
import { fetchCloseAllOrders } from "../../core/store/slices/orders.slice";
import { useSnackbar } from "../../core/hooks/useSnackbar";

export const RestaurantDetailContainer = () => {
  const { id } = useParams();
  const { payload } = useRestaurant(id);
  const [selectedTab, setSelectedTab] = useState<string>("details");
  const { showMessage } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openConfirm, closeConfirm] = useDialog(ConfirmDialog);

  const handleDeactivate = () => {
    openConfirm({
      title: "Deactivate account",
      message: "Are really want to deactivate this account?",
      onConfirm: async () => {
        await dispatch(fetchDeactivateRestaurant(id));
        closeConfirm();
        navigate(ROUTE_RESTAURANTS);
      },
    });
  };

  const handleActivate = () => {
    openConfirm({
      title: "Activate account",
      message: "Are really want to activate this account?",
      onConfirm: async () => {
        await dispatch(fetchActivateRestaurant(id));
        closeConfirm();
        navigate(ROUTE_RESTAURANTS);
      },
    });
  };

  const handleCloseAllOrders = () => {
    openConfirm({
      title: "Close all orders without charge",
      message: "Are really want to close all orders without charging?",
      onConfirm: async () => {
        await dispatch(fetchCloseAllOrders(id));

        showMessage(
          "All restaurant orders has been closed successfully without charge",
          "success"
        );
        closeConfirm();
      },
    });
  };

  return (
    <>
      <DetailHeader
        title={payload?.name}
        divider={false}
        header={
          <Breadcrumbs
            items={[
              { label: "Restaurants", url: ROUTE_RESTAURANTS },
              { label: payload?.name },
            ]}
          />
        }
        meta={
          <StatusBadge
            label={payload?.isActive ? "Active" : "Inactive"}
            variant={restaurantStatusState(payload?.isActive)}
          />
        }
      >
        {selectedTab === "details" && (
          <>
            <Button color="secondary" onClick={handleCloseAllOrders}>
              Close all orders (without charge)
            </Button>
            <Button component={Link} to={url(ROUTE_RESTAURANTS_EDIT, { id })}>
              Edit
            </Button>
            <Button
              color="secondary"
              onClick={payload?.isActive ? handleDeactivate : handleActivate}
            >
              {payload?.isActive ? "Deactivate" : "Activate"}
            </Button>
          </>
        )}
      </DetailHeader>

      <Tabs
        hashed
        onChange={(value) => setSelectedTab(value)}
        items={[
          {
            label: "Details",
            key: "details",
            content: <Detail payload={payload} />,
          },
          {
            label: "Orders",
            key: "orders",
            content: <Orders />,
          },
          {
            label: "Waiters",
            key: "waiters",
            content: <Waiters />,
          },
          {
            label: "Payouts",
            key: "payouts",
            content: <Payouts />,
          },
        ]}
      />
    </>
  );
};
