import { formatCurrency } from "../utils/currency-format.utils";

export const orderSummaryColumns = [
  {
    title: "Title",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    cellProps: {
      width: 130,
    },
    render: (price) => formatCurrency(price),
  },
  {
    title: "Qty",
    dataIndex: "quantity",
    key: "quantity",
    cellProps: {
      width: 130,
    },
  },
  {
    title: "Total",
    dataIndex: "total",
    key: "total",
    cellProps: {
      width: 130,
    },
    render: (v, row) => formatCurrency(+row.price * +row.quantity),
  },
];
