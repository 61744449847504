import { useState, ComponentType } from "react";
import { Button, Stack } from "@mui/material";
import useStyles from "./styles";
import clsx from "clsx";

interface Props {
  items: {
    label: string;
    value: string;
  }[];
  onChange?: (value: string) => void;
  defaultValue?: string;
}

export const FieldRadioGroup: ComponentType<Props> = ({
  items,
  onChange,
  defaultValue = null,
}) => {
  const [value, setValue] = useState<string | null>(defaultValue);
  const classes = useStyles();

  const handleClick = (value: string) => () => {
    setValue(value);
    onChange && onChange(value);
  };

  return (
    <Stack
      className={classes.root}
      direction="row"
      display="inline-flex"
      spacing={0.5}
      p={0.5}
    >
      {items.map((item) => {
        return (
          <Button
            key={item.value}
            size="small"
            className={clsx(classes.button, {
              [classes.buttonActive]: item.value === value,
            })}
            variant="text"
            onClick={handleClick(item.value)}
          >
            {item.label}
          </Button>
        );
      })}
    </Stack>
  );
};
