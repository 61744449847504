import qs from "qs";
import { generatePath } from "react-router-dom";
import {
  GET_ALL_TABLES,
  GET_TABLE,
  UPDATE_TABLE,
  CREATE_TABLE,
} from "../api.routes";
import { handleError } from "../utils";
import { BaseApi } from "./base.api";

export class TableApi extends BaseApi {
  /**
   * Get All Tables
   */
  public getAllTables(params): Promise<any> {
    const queryString = qs.stringify(params, { addQueryPrefix: true });
    return this.httpClient
      .get(`${GET_ALL_TABLES}${queryString}`)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Get Table
   */
  public getTable(id): Promise<any> {
    return this.httpClient
      .get(generatePath(GET_TABLE, { id }))
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Update Table
   */
  public updateTable(id, params): Promise<any> {
    return this.httpClient
      .put(generatePath(UPDATE_TABLE, { id }), params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
  /**
   * Create Table
   */
  public createTable(params): Promise<any> {
    console.log(params);
    return this.httpClient
      .post(CREATE_TABLE, params)
      .then((response) => {
        return response;
      })
      .catch(({ response }) => {
        throw new Error(handleError(response));
      });
  }
}
